import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { useUserUpdateMutation } from "@/gql/mutations";
import React, { FC } from "react";
type ActiveToggleProps = {
  id: string;
  isActive: boolean;
};

export const ActiveToggleButton: FC<ActiveToggleProps> = ({ id, isActive }) => {
  const { t } = useTranslation("admin");
  const [checked, setChecked] = React.useState(isActive);
  const { succeeded, failed } = useAddToast();

  const [updateUser, { loading }] = useUserUpdateMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const errors = data?.userUpdate?.accountErrors;
      if (errors && errors.length > 0) {
        const error = formatMessageErrors(errors);
        failed(error);
      } else {
        setChecked(data?.userUpdate?.user?.isActive as boolean);
        succeeded(t("User Updated Successfully"));
      }
    },
    onError: () => {
      failed(t("User Update Failed"));
    },
  });

  const handleChangeActivity = event => {
    const isChecked: boolean = event.target.checked;
    if (loading) return;
    updateUser({
      variables: {
        id: id,
        input: {
          isActive: isChecked,
        },
      },
    });
  };

  return <CustomToggleButton checked={checked} onChange={handleChangeActivity} isLoading={loading} />;
};
