import { useTranslation } from "@toolkit/i18n";
import { Box, Button, QuestionIcon, ResponseIcon } from "@toolkit/ui";
import { FC } from "react";
import { SurveyDetailsHeaderProps } from "./types";
import { useSurveyDetailsHeaderStyle } from "./useSurveyDetailsHeaderStyle";

export const SurveyDetailsHeader: FC<SurveyDetailsHeaderProps> = ({ selectedTab, responsesCount, showResponsesTab, onChangeTab }) => {
  const { t } = useTranslation("admin");
  const { classes, cx } = useSurveyDetailsHeaderStyle();

  return (
    <Box className={classes.root}>
      <Button
        variant='text'
        startIcon={<QuestionIcon />}
        className={cx(classes.button, { [classes.selected]: selectedTab === "Questions" })}
        onClick={() => onChangeTab("Questions")}
      >
        {t("Questions")}
      </Button>
      {showResponsesTab && (
        <Button
          variant='text'
          startIcon={<ResponseIcon />}
          className={cx(classes.button, { [classes.selected]: selectedTab === "Responses" })}
          onClick={() => onChangeTab("Responses")}
        >
          {t("Responses")}
          <div className={classes.badge}>{responsesCount}</div>
        </Button>
      )}
    </Box>
  );
};
