import { BranchInput, City } from "@/schema/types";
import { getItemByPredicate } from "@toolkit/apollo";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { client } from "shared/configs/apollo";
import { BranchFormProps } from "./BranchesForm.types";
import { SearchByLicenseID } from "./SearchByLicenseID";
import { BranchWorkingHours } from "./components/BranchWorkingHours.component";
import Address from "./components/BranchesAddress.component";
import { GeneralInformation } from "./components/GeneralInformation/BranchesGeneralInfo.component";
import { useBranchFormHook } from "./useBranchesInfoHook";
import { fillMappedDataToForm } from "./utils";

const BranchForm: FC<BranchFormProps> = ({ isEditMode, branch, onSubmit: handleOnSubmit, loading: orgLoading, errors, isSubmitting }) => {
  const { t } = useTranslation("admin");

  const { methods, location, onSubmit, handleLocation, handleSubmit } = useBranchFormHook({
    branch,
    handleOnSubmit,
  });
  const [isLoadingWithSearch, setIsLoadingWithSearch] = useState(orgLoading);
  const handleLoading = (value: boolean) => {
    setIsLoadingWithSearch(!!value);
  };
  useEffect(() => {
    setIsLoadingWithSearch(orgLoading);
  }, [orgLoading]);
  const loading = isLoadingWithSearch;
  const doYouHaveBranch = isEditMode ? branch != undefined : true;
  const formButtonTitle = isEditMode ? t("Update") : t("Create");

  const handleOnFillMappedDataToForm = (mappedInput: BranchInput) => {
    fillMappedDataToForm(mappedInput, methods);
    const predicate = (val: City) => {
      return val?.name?.toLowerCase?.() === mappedInput?.addressInput?.city?.toLowerCase?.();
    };

    const city = getItemByPredicate(client, "City", predicate);
    methods.setValue("addressInput.city", city as any, {
      shouldValidate: true,
    });
    mappedInput.addressInput?.coordinates && handleLocation(mappedInput.addressInput?.coordinates);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions isLoading={loading} hasCancel hasFormButton formButtonTitle={formButtonTitle} newButtonDisabled={isSubmitting} />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            {!isEditMode && (
              <Grid item xs={12}>
                <FormCard title={t("Load Information By License ID")} loading={loading || isLoadingWithSearch} doYouHaveData={true}>
                  <SearchByLicenseID onLoading={handleLoading} onFillMappedDataToForm={handleOnFillMappedDataToForm} />
                </FormCard>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormCard title={t("General Information")} loading={loading} doYouHaveData={doYouHaveBranch}>
                <GeneralInformation defaultValue={branch} errorsInput={errors} isEditMode={isEditMode} />
              </FormCard>
            </Grid>
            <BranchWorkingHours errorsInput={errors} loading={!!loading} doYouHaveBranch={doYouHaveBranch} branch={branch} />
            <Grid item xs={12}>
              <FormCard title={t("Address And Location")} loading={loading} doYouHaveData={doYouHaveBranch}>
                <Address defaultValues={branch} errorsInput={errors} location={location} onLocationChange={handleLocation} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};

export default BranchForm;
