import { VendorBranchesQuery } from "@/gql/queries";
import { Vendor } from "@/schema/types";
import { getWorkingHoursWithTimeZone } from "@/shared/components";
import { i18n } from "@toolkit/i18n";
import { formatTime, getDayString, InfoGroup, InfoItems } from "@toolkit/ui";
import { isEmpty } from "lodash-es";

export const getBankingInfoItems = (bankInfo?: Vendor["bankInfo"]): InfoItems => {
  return bankInfo
    ? [
        {
          label: i18n.t("Bank Name", { ns: "admin" }),
          value: bankInfo?.bankName || "-",
        },
        {
          label: i18n.t("Account Name", { ns: "admin" }),
          value: bankInfo?.accountName || "-",
        },
        {
          label: i18n.t("Account Number", { ns: "admin" }),
          value: bankInfo?.accountNumber || "-",
        },
        {
          label: i18n.t("IBAN", { ns: "admin" }),
          value: bankInfo?.iban || "-",
        },
      ]
    : undefined;
};

export const getOwnerInfoItems = (ownerInfo: Pick<Vendor, "ownerName" | "nationalId">) => {
  return ownerInfo
    ? [
        {
          label: i18n.t("Owner Name", { ns: "admin" }),
          value: ownerInfo?.ownerName || "-",
        },
        {
          label: i18n.t("National Id", { ns: "admin" }),
          value: ownerInfo?.nationalId || "-",
        },
      ]
    : undefined;
};

export const getWorkingHoursInfoGroup = (
  branch: NonNullable<NonNullable<NonNullable<VendorBranchesQuery["vendor"]>["branches"]>["edges"][0]["node"]>,
  timeZone = 3
): InfoGroup => {
  const getWorkingHours = getWorkingHoursWithTimeZone(branch?.workingHours, timeZone);

  const headerItems = !isEmpty(getWorkingHours)
    ? [
        { label: i18n.t("Day", { ns: "admin" }), value: "" },
        { label: i18n.t("Start Time", { ns: "admin" }), value: "" },
        { label: i18n.t("End Time", { ns: "admin" }), value: "" },
      ]
    : [
        {
          value: i18n.t("No Working Hours Provided", { ns: "admin" }),
        },
      ];

  const workingHourItems =
    getWorkingHours?.flatMap(workingHour => {
      const dayName = i18n.t(getDayString(String(workingHour.day)), { ns: "admin" });

      if (workingHour.openTimeRanges.length === 1) {
        const timeRange = workingHour.openTimeRanges[0];
        return [
          { label: dayName, direction: "row" },
          { value: formatTime(timeRange.openTime, i18n.language) },
          { value: formatTime(timeRange.closeTime, i18n.language) },
        ];
      }

      return [
        {
          label: dayName,
          direction: "row",
        },
        {
          value: workingHour?.openTimeRanges?.map(range => formatTime(range.openTime, i18n.language)),
          valueDisplayMode: "stacked",
        },
        {
          value: workingHour?.openTimeRanges?.map(range => formatTime(range.closeTime, i18n.language)),
          valueDisplayMode: "stacked",
        },
      ];
    }) || [];

  return {
    title: branch?.name
      ? i18n.t("Workings hours for {{name}}", { name: branch?.name, ns: "admin" })
      : i18n.t("Branch Working Hours", { ns: "admin" }),
    variant: "accordion",
    layout: "three-columns",
    items: [...headerItems, ...workingHourItems],
  };
};
