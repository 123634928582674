import { User, UserSortField } from "@/schema/types";
import { ellipseName } from "@/utils";
import { getBranchesAutocompleteFilter } from "@health/autocompletes";
import { CredentialDialog } from "@health/domains";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { ActiveToggleButton } from "./ActiveToggleButton";

export const useUsersColumns = (): CustomTableColumnProps<User>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "firstName",
        header: t("First Name"),
        accessor: ({ firstName }) => <Typography>{ellipseName(String(firstName), 30)}</Typography>,
        sort: {
          columnEnum: UserSortField.FirstName,
        },
        seetings: {
          hideFromSettings: true,
          disableToggleVisibility: true,
        },
      },
      {
        key: "lastName",
        header: t("Last Name"),
        accessor: ({ lastName }) => <Typography>{ellipseName(String(lastName), 30)}</Typography>,
        sort: {
          columnEnum: UserSortField.LastName,
        },
      },
      {
        key: "email",
        header: t("Email"),
        accessor: "email",
        sort: {
          columnEnum: UserSortField.Email,
        },
      },
      {
        key: "mobile",
        header: t("Mobile"),
        accessor: "mobile",
      },
      {
        key: "eRxHubCredential",
        header: t("eRxHub Credential"),
        accessor: ({ id, isActive }) => (
          <CredentialDialog isDisabled={!isActive} targetId={id || ""} type={"clinician"} title={t("eRxHub Credential")} />
        ),
      },
      {
        key: "isActive",
        header: t("Is Active"),
        accessor: row => <ActiveToggleButton id={row?.id} isActive={row?.isActive} />,
      },
      {
        key: "search",
        header: t("Search"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "appRole",
        header: t("App Role"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("AppRoleType", "appRole"),
      },
      {
        key: "appType",
        header: t("App Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("AppType", "appType"),
      },
      {
        key: "vendorUserType",
        header: t("Vendor User Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("VendorUserType", "vendorUserType"),
      },
      {
        key: "status",
        header: t("Status"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("UserStatus", "status"),
      },
      {
        key: "branchId",
        header: t("Branch"),
        showOnlyForFilterField: true,
        filter: getBranchesAutocompleteFilter({ name: "branchId" }),
      },
      {
        key: "dateJoinedFrom",
        header: t("Date Joined From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.gte",
        },
      },
      {
        key: "dateJoined",
        header: t("Date Joined To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.lte",
        },
      },
    ],
    [t]
  );
};
