import { OptimaEdit, OptimaEditInput } from "@/schema/types";
import { createOptimaActionAutocompleteOption } from "@health/autocompletes";
import { optimaPriorityOptionsMap } from "@health/enum-options";
import { IOptimaEditUpsertFormValues } from "pages/OptimaEdits/forms/OptimaEditUpsert/OptimaEditUpsertFormSchema";

export const convertOptimaEditToFormValues = (optimaEdit: OptimaEdit): IOptimaEditUpsertFormValues => {
  return {
    code: optimaEdit?.code!,
    category: optimaEdit?.category!,
    subCategory: optimaEdit?.subCategory!,
    source: optimaEdit?.source!,
    priority: optimaPriorityOptionsMap[optimaEdit?.priority!],
    details: optimaEdit?.details!,
    rejectionCode: optimaEdit?.["rejectionCode"]!,
    message: optimaEdit?.message!,
    actions: optimaEdit?.actions?.length ? optimaEdit?.actions?.map(item => createOptimaActionAutocompleteOption(item!)) : [],
    isActive: !!optimaEdit?.isActive,
  };
};

export const convertOptimaEditFormValuesToBackEndValues = (values: IOptimaEditUpsertFormValues): OptimaEditInput => {
  return {
    code: values?.code!,
    category: values?.category!,
    subCategory: values?.subCategory!,
    rejectionCode: values?.rejectionCode!,
    priority: values?.priority?.value,
    source: values?.source!,
    details: values?.details!,
    message: values?.message!,
    actionIds: values?.actions?.map(item => item?.value?.id),
    isActive: values?.isActive!,
  };
};
