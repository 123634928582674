import { Branch, BranchOrderField } from "@/schema/types";
import { CredentialDialog } from "@health/domains";
import { getAutocompleteEnumFilter, vendorTypeOptionsMap } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, Tooltip, Typography } from "@toolkit/ui";
import { ActiveSwitch } from "../components/ActiveSwitch.components";
import { getAddressesInfoItems } from "@/shared/utils/CellInfo.utils";
import { getWorkingHoursInfoGroup } from "../others/cellInfo.utils";
import { useSiteSettingsGetQuery } from "@/gql/queries";
import { isEmpty } from "lodash-es";
import { providersManagementPaths } from "@/pages/ProvidersManagement/constants/providers-management-paths";

const multiLineAccessor = (key: keyof Branch) => {
  return row => {
    const valueLines = row?.[key]?.split("\\n") || [];
    return (
      <Box sx={{ width: "130px" }}>
        <Tooltip title={row?.name ?? ""}>
          <span>
            {valueLines.map(item => (
              <Typography noWrap key={item} component={"div"}>
                {item}
              </Typography>
            ))}
          </span>
        </Tooltip>
      </Box>
    );
  };
};

export const useGetBranchesColumns = (canManageBranches: boolean): CustomTableColumnProps<Branch>[] => {
  const { t } = useTranslation("admin");
  const { data: setting } = useSiteSettingsGetQuery();
  const timeZone = setting?.siteSettings?.timeZone! || 3;
  return [
    {
      key: "nameFilter",
      header: t("Name"),
      settings: {
        hideFromSettings: true,
      },
      showOnlyForFilterField: true,
      filter: {
        type: "string",
        name: "name_Icontains",
      },
    },
    {
      key: "name",
      header: t("Name"),
      isHidden: false,
      sort: {
        columnEnum: BranchOrderField.Name,
      },
      type: "string",
      settings: {
        hideFromSettings: true,
        disableToggleVisibility: true,
      },
      accessor: multiLineAccessor("name"),
    },
    {
      key: "description",
      header: t("Description"),
      filter: {
        type: "string",
        name: "description_Icontains",
      },
      accessor: multiLineAccessor("description"),
    },
    {
      key: "credential",
      header: t("eRxHub Credential"),
      accessor: ({ id, isActive }) => (
        <CredentialDialog isDisabled={!isActive} type='pharmacy' targetId={id} title={t("eRxHub Credential")} />
      ),
    },
    {
      key: "address",
      header: t("Address"),
      type: "info",
      infoCellOptions: {
        title: t("Address information"),
        items: ({ address }) => getAddressesInfoItems(address),
        emptyMessage: t("There is No Address Information"),
      },
      isHidden: false,
    },
    {
      key: "contactNumber",
      header: t("Contact Number"),
      accessor: "contactNumber",
      filter: {
        type: "string",
        name: "contactNumber_Icontains",
      },
    },
    {
      key: "healthLicense",
      header: t("Health License"),
      accessor: "healthLicense",
    },
    {
      key: "types",
      header: t("Type"),
      accessor: ({ type }) => vendorTypeOptionsMap[type!]?.label,
      filter: getAutocompleteEnumFilter("VendorType", "type"),
    },
    {
      key: "users",
      header: t("Users"),
      type: "redirect",
      redirectCellOptions: {
        link: ({ id, vendor }) => providersManagementPaths.branchesUsersPath.fullPathWithParams(vendor?.id, id),
        tooltipMessage: row => t("show {{name}} users", { name: pickLocalizedValue(row?.name, row.nameAr) }),
        isDisabled: !canManageBranches,
      },
    },
    {
      key: "active",
      header: t("Active"),
      accessor: row => <ActiveSwitch id={row?.id} isActive={row?.isActive} disabled={!row?.vendor?.isActive || !canManageBranches} />,
      isHidden: false,
      settings: {
        disableToggleVisibility: false,
      },
      filter: getAutocompleteEnumFilter("YesNo", "isActive"),
    },
    {
      key: "workingHours",
      header: t("Working Hours"),
      type: "info",
      infoCellOptions: {
        title: t("Working Hours"),
        groups: branch => (branch ? [getWorkingHoursInfoGroup(branch, timeZone)] : undefined),
        emptyMessage: t("There is No Working Hours"),
        containerProps: {
          variant: "drawer",
          sx: { maxWidth: "sm" },
        },
        disableShowButton: branch => isEmpty(branch?.workingHours),
      },
    },
    {
      key: "acceptsDelivery",
      header: t("Accepts Delivery"),
      showOnlyForFilterField: true,
      filter: getAutocompleteEnumFilter("YesNo", "acceptsDelivery"),
    },
  ];
};
