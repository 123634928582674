import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { OptimaEditListDocument, useOptimaEditCreateMutation } from "pages/OptimaEdits/gql";
import { IOptimaEditUpsertFormEvent, OptimaEditUpsertForm } from "pages/OptimaEdits/forms/OptimaEditUpsert/OptimaEditUpsertForm";
import { convertOptimaEditFormValuesToBackEndValues } from "pages/OptimaEdits/others";
import { FC } from "react";

export type IOptimaEditCreateContainerEvent = {
  type: "SUBMIT_SUCCESS";
};

type OptimaEditCreateContainerProps = {
  onChange: (event: IOptimaEditCreateContainerEvent) => void;
};

export const OptimaEditCreateContainer: FC<OptimaEditCreateContainerProps> = props => {
  const { onChange } = props;

  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();

  const [createOptimaEdit, { loading: isSubmitting }] = useOptimaEditCreateMutation({
    onCompleted: () => {
      succeeded(t("Optima Edit created successfully"));

      onChange({
        type: "SUBMIT_SUCCESS",
      });
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [OptimaEditListDocument],
  });

  const onOptimaEditUpsertFormChange = (event: IOptimaEditUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertOptimaEditFormValuesToBackEndValues(event.payload.values);

      createOptimaEdit({
        variables: {
          input: values,
        },
      });
    }
  };

  return <OptimaEditUpsertForm buttonLabel={t("Create")} isButtonDisabled={isSubmitting} onChange={onOptimaEditUpsertFormChange} />;
};
