import { zodEnumSchema } from "@health/enum-options";
import { zodSchema } from "@health/autocompletes";
import { z } from "zod";
import { validateUserVendor, validateUserVendorTypeDoctor } from "../../others";
import {
  userProviderDoctorDetailsFormDefaultValues,
  userProviderDoctorDetailsFormSchema,
} from "pages/UsersProviders/forms/UserProviderDoctorDetails/UserProviderDoctorDetailsFormSchema";
import { i18n } from "@toolkit/i18n";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";

export const userProviderUpsertFormSchema = (isAdmin?: boolean, isUpdateMode?: boolean) => {
  return z
    .object({
      firstName: z.string().min(3).max(30),
      lastName: z.string().min(3).max(30),
      nationalId: z.string().min(1),
      mobile: z.string(),
      email: z.string().email(),
      password: isUpdateMode
        ? z.string().optional()
        : z
            .string({
              required_error: i18n.t("Invalid password"),
            })
            .min(8, { message: i18n.t("Password must be at least 8 characters") })
            .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*-])[A-Za-z\d!@#$%&*-]{8,}$/, {
              message: i18n.t(
                "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
              ),
            }),
      dateOfBirth: z.string().nullish(),
      gender: zodEnumSchema.userGender,
      appRole: zodEnumSchema.appRoleType.nullish(),
      vendorUserType: zodEnumSchema.vendorUserType,
      isActive: z.boolean(),
      provider: zodSchema.vendor.nullish(),
      branches: z.array(zodSchema.branch),
      defaultBranch: zodSchema.branch.nullish(),
      departments: z.array(zodSchema.department).nullish(),
      healthLicenseNumber: z.string().nullish(),
      healthLicenseStartDate: z.string().nullish(),
      healthLicenseEndDate: z.string().nullish(),
      doctorInfo: userProviderDoctorDetailsFormSchema.nullish(),
    })
    .superRefine((values, ctx) => {
      if (isAdmin && !values?.provider) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["provider"],
          message: i18n.t("Required"),
        });
      }
      if (!values.mobile && !isValidPhoneNumber(String(values.mobile))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Invalid mobile number"),
          path: ["mobile"],
        });
      }
      validateUserVendor(values, ctx);
      validateUserVendorTypeDoctor(values, ctx);
    });
};

export type IUserProviderUpsertForm = z.infer<ReturnType<typeof userProviderUpsertFormSchema>>;

export const userProviderUpsertFormDefaultValues: Partial<IUserProviderUpsertForm> = {
  firstName: undefined,
  lastName: undefined,
  nationalId: undefined,
  mobile: undefined,
  email: undefined,
  password: undefined,
  dateOfBirth: undefined,
  gender: undefined,
  appRole: undefined,
  vendorUserType: undefined,
  isActive: true,
  provider: undefined,
  branches: [],
  defaultBranch: undefined,
  departments: undefined,
  healthLicenseNumber: undefined,
  healthLicenseStartDate: undefined,
  healthLicenseEndDate: undefined,
  doctorInfo: userProviderDoctorDetailsFormDefaultValues as IUserProviderUpsertForm["doctorInfo"],
};
