import { HealthProgramMembershipRequest, HealthProgramMembershipRequestSortingField } from "@/schema/types";
import { getPatientsAutocompleteFilter } from "@health/autocompletes";
import { coverageByTypeOptionsMap, getAutocompleteEnumFilter, requestStatusOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CheckMarkIcon, CustomTableColumnProps, formatDate, IconButton, RejectIcon, useTheme } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { HealthProgramMembershipRequestApproveModalApi } from "../../modals/HealthProgramMembershipRequestApprove/HealthProgramMembershipRequestApproveModal";
import { HealthProgramMembershipRequestRejectModalApi } from "../../modals/HealthProgramMembershipRequestReject/HealthProgramMembershipRequestRejectModal";
import { isHealthProgramMembershipRequestAlreadyApprovedOrRejected } from "../../others";

export const useHealthProgramMembershipRequestListContainerColumns = (): CustomTableColumnProps<HealthProgramMembershipRequest>[] => {
  const { t } = useTranslation("admin");

  const theme = useTheme();

  const onApproveClick = useCallback((healthProgramMembershipRequest: HealthProgramMembershipRequest) => {
    HealthProgramMembershipRequestApproveModalApi.open({ healthProgramMembershipRequest });
  }, []);

  const onRejectClick = useCallback((healthProgramMembershipRequest: HealthProgramMembershipRequest) => {
    HealthProgramMembershipRequestRejectModalApi.open({ healthProgramMembershipRequest });
  }, []);

  return useMemo(
    () => [
      {
        key: "insuranceId",
        header: t("Insurance Id"),
        accessor: "insuranceId",
        sort: {
          columnEnum: HealthProgramMembershipRequestSortingField.InsuranceId,
        },
        filter: {
          type: "string",
          name: "insuranceId",
        },
      },
      {
        key: "patientName",
        header: t("Patient Name"),
        type: "truncated-text",
        accessor: ({ user }) => user?.fullName,
        sort: {
          columnEnum: HealthProgramMembershipRequestSortingField.Name,
        },
        filter: getPatientsAutocompleteFilter({ name: "patientId" }),
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => requestStatusOptionsMap[status!]?.label,
        filter: getAutocompleteEnumFilter("RequestStatus", "status"),
      },
      {
        key: "coverageBy",
        header: t("Coverage By"),
        accessor: ({ healthProgramMember }) => coverageByTypeOptionsMap[healthProgramMember?.coverageBy!]?.label,
        filter: getAutocompleteEnumFilter("CoverageByType", "coverageBys"),
      },
      {
        key: "membershipStartDate",
        header: t("Membership Start Date"),
        accessor: ({ healthProgramMember }) => formatDate(healthProgramMember?.membershipStart),
      },
      {
        key: "membershipEndDate",
        header: t("Membership End Date"),
        accessor: ({ healthProgramMember }) => formatDate(healthProgramMember?.membershipEnd),
      },
      {
        key: "created",
        header: t("Created"),
        type: "datetime",
        accessor: "created",
        sort: {
          columnEnum: HealthProgramMembershipRequestSortingField.Created,
        },
      },
      {
        key: "approve",
        header: t("Approve"),
        accessor: row => (
          <IconButton
            disabled={isHealthProgramMembershipRequestAlreadyApprovedOrRejected(row?.status!)}
            onClick={() => onApproveClick(row)}
          >
            <CheckMarkIcon color={"success"} />
          </IconButton>
        ),
      },
      {
        key: "reject",
        header: t("Reject"),
        accessor: row => (
          <IconButton disabled={isHealthProgramMembershipRequestAlreadyApprovedOrRejected(row?.status!)} onClick={() => onRejectClick(row)}>
            <RejectIcon color={"error"} sx={{ fontSize: theme.mixins.fonts.fontSize.xl }} />
          </IconButton>
        ),
      },
      {
        key: "membershipStartFilterFrom",
        header: t("Membership Start Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "membershipStart.gte",
        },
      },
      {
        key: "membershipStartFilterTo",
        header: t("Membership Start Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "membershipStart.lte",
        },
      },
      {
        key: "membershipEndFilterFrom",
        header: t("Membership End Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "membershipEnd.gte",
        },
      },
      {
        key: "membershipEndFilterTo",
        header: t("Membership End Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "membershipEnd.lte",
        },
      },
      {
        key: "insuranceCompanyLicenseNumbers",
        header: t("Insurance Company License Number"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "insuranceCompanyLicenseNumbers",
          getValueForBackend: value => (value ? [value] : null),
        },
      },
    ],
    [onApproveClick, onRejectClick, t]
  );
};
