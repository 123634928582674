import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useGetPrescriptionsOrdersQuery } from "pages/OrdersTracking/gql/queries";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { usePrescriptionsOrdersTrackingColumns } from "./usePrescriptionsOrdersTrackingColumns";

export const PrescriptionsOrdersTrackingPage: FC<{ isAdmin }> = ({ isAdmin }) => {
  return (
    <GridProvider
      gridName='prescriptions-orders'
      columns={usePrescriptionsOrdersTrackingColumns({ isAdmin })}
      query={useGetPrescriptionsOrdersQuery}
      variables={{ isAdmin, filter: { parentOrders: isAdmin } }}
      filterInput={{
        parentOrders: isAdmin,
      }}
      hideFilterInput
      hasTableSetting
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
