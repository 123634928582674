import { OptimaEdit } from "@/schema/types";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useOptimaEditListQuery } from "pages/OptimaEdits/gql";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { useOptimaEditListContainerColumns } from "./useOptimaEditListContainerColumns";

export type IOptimaEditListContainerEvent =
  | {
      type: "ADD_CLICK";
    }
  | {
      type: "UPDATE_CLICK";
      payload: {
        item: OptimaEdit;
      };
    };

type OptimaEditListContainerProps = {
  onChange: (event: IOptimaEditListContainerEvent) => void;
};

export const OptimaEditListContainer: FC<OptimaEditListContainerProps> = props => {
  const { onChange } = props;

  const onAddNewItemClick = () => {
    onChange({
      type: "ADD_CLICK",
    });
  };

  const onEditRowClick = (item: OptimaEdit) => {
    onChange({
      type: "UPDATE_CLICK",
      payload: { item },
    });
  };

  return (
    <GridProvider
      gridName={"optimaEditList"}
      query={useOptimaEditListQuery}
      columns={useOptimaEditListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
