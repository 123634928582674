import { z } from "zod";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { CancellationReasonsAutocompleteQuery } from "./gql";

export type ICancellationReasonAutocomplete = Omit<
  NonNullable<NonNullable<CancellationReasonsAutocompleteQuery["cancellationReasons"]>[number]>,
  "__typename"
>;

export const cancellationReasonSchema = createZodAutocompleteFromType<ICancellationReasonAutocomplete>({
  id: z.string(),
  text: z.string().nullish(),
  text_ar: z.string().nullish(),
});
