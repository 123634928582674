import { CallbackRequest } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography } from "@toolkit/ui";
import { startCase } from "lodash";
import { useMemo } from "react";
import { RequestHandlingModal } from "../components/RequestHandling/RequestHandlingModal";

export type CallbackRequestListColumnsTypes = CustomTableColumnProps<CallbackRequest>[];

export const useCallbackRequestListColumns = (): CallbackRequestListColumnsTypes => {
  const { t } = useTranslation("admin");
  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("Request ID"),
        accessor: row => <Typography sx={{ maxWidth: "100px", width: "100px" }}>{row.id}</Typography>,
      },
      {
        key: "fullName",
        header: t("Patient Name"),
        type: "truncated-text",
        accessor: row => row?.user?.fullName,
      },
      {
        key: "contactNumber",
        header: t("Contact Number"),
        type: "truncated-text",
        accessor: row => row?.patient?.contactNumber,
      },
      {
        key: "serviceName",
        header: t("Service Name"),
        type: "truncated-text",
        accessor: row => row?.serviceName,
      },
      {
        key: "status",
        header: t("Request Status"),
        accessor: row => t(startCase(row?.status?.toLowerCase())),
        filter: getAutocompleteEnumFilter("CallbackRequestStatus", "status"),
      },
      {
        key: "patientNotes",
        header: t("Patient Notes"),
        type: "info",
        infoCellOptions: {
          title: t("Patient Notes"),
          items: ({ patientNotes }) => [
            {
              value: patientNotes || t("Patient didn't provide notes"),
              valueDisplayMode: "multiple-line-string",
            },
          ],
          layout: "one-column",
          disableShowButton: ({ patientNotes }) => !patientNotes,
        },
      },
      {
        key: "requestDate",
        header: t("Request Date"),
        type: "date",
        accessor: "created",
      },
      {
        key: "requestHandling",
        header: t("Request Handling"),
        accessor: row => <RequestHandlingModal callBackRequest={row} />,
      },
    ];
  }, [t]);
};
