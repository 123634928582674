import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMarketplaceOrdersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.MarketplaceOrderFilterInput>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.MarketplaceOrderSortingInput>;
  isAdmin: Types.Scalars['Boolean']['input'];
}>;


export type GetMarketplaceOrdersQuery = { __typename?: 'Query', marketplaceOrders?: { __typename?: 'MarketplaceOrderConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null, edges: Array<{ __typename?: 'MarketplaceOrderEdge', node: { __typename?: 'MarketplaceOrder', id: string, status: Types.MarketplaceOrderStatus, totalNetAmount: any, cancellationReason?: string | null, createdDate: any, type: Types.MarketplaceOrderType, user: { __typename?: 'User', nationalId?: string | null, fullName?: string | null, mobile?: string | null, email?: string | null, id: string }, vendor?: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null }, orderEvents: Array<{ __typename?: 'MarketplaceOrderEvent', date: any, id: string, status: Types.MarketplaceOrderStatus, user: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, defaultBranch?: { __typename?: 'Branch', name: string } | null } }> } }> } | null };


export const GetMarketplaceOrdersDocument = gql`
    query getMarketplaceOrders($first: Int, $after: String, $before: String, $filter: MarketplaceOrderFilterInput, $last: Int, $sortBy: MarketplaceOrderSortingInput, $isAdmin: Boolean!) {
  marketplaceOrders(
    first: $first
    after: $after
    before: $before
    filter: $filter
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        status
        totalNetAmount
        cancellationReason
        user {
          nationalId
          fullName
          mobile
          email
          id
        }
        vendor @include(if: $isAdmin) {
          id
          name
          nameAr
        }
        createdDate
        orderEvents {
          date
          id
          status
          user {
            id
            firstName
            lastName
            defaultBranch {
              name
            }
          }
        }
        type
      }
    }
  }
}
    `;

/**
 * __useGetMarketplaceOrdersQuery__
 *
 * To run a query within a React component, call `useGetMarketplaceOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketplaceOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketplaceOrdersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useGetMarketplaceOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetMarketplaceOrdersQuery, GetMarketplaceOrdersQueryVariables> & ({ variables: GetMarketplaceOrdersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarketplaceOrdersQuery, GetMarketplaceOrdersQueryVariables>(GetMarketplaceOrdersDocument, options);
      }
export function useGetMarketplaceOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarketplaceOrdersQuery, GetMarketplaceOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarketplaceOrdersQuery, GetMarketplaceOrdersQueryVariables>(GetMarketplaceOrdersDocument, options);
        }
export function useGetMarketplaceOrdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMarketplaceOrdersQuery, GetMarketplaceOrdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMarketplaceOrdersQuery, GetMarketplaceOrdersQueryVariables>(GetMarketplaceOrdersDocument, options);
        }
export type GetMarketplaceOrdersQueryHookResult = ReturnType<typeof useGetMarketplaceOrdersQuery>;
export type GetMarketplaceOrdersLazyQueryHookResult = ReturnType<typeof useGetMarketplaceOrdersLazyQuery>;
export type GetMarketplaceOrdersSuspenseQueryHookResult = ReturnType<typeof useGetMarketplaceOrdersSuspenseQuery>;
export type GetMarketplaceOrdersQueryResult = Apollo.QueryResult<GetMarketplaceOrdersQuery, GetMarketplaceOrdersQueryVariables>;