import { Box, Button, CircularProgress, TextField } from "@toolkit/ui";
import React, { useState, ChangeEvent } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useCustomFormContext } from "@toolkit/core";
import { useUserProviderNationalIdGetLazyQuery } from "../../gql";
import { IUserProviderUpsertForm } from "../UserProviderUpsert/UserProviderUpsertFormSchema";
import { convertUserProviderNationalIdToFormValues } from "../../others";
import { useUserProviderNationalIdFormStyle } from "./useUserProviderNationalIdFormStyle";

export const UserProviderNationalIdForm = () => {
  const [nationalId, setNationalId] = useState("");

  const { t } = useTranslation("domains");

  const { classes } = useUserProviderNationalIdFormStyle();

  const form = useCustomFormContext<IUserProviderUpsertForm>();

  const { setValues, reset } = form;

  const [getUserProviderNationalId, { loading }] = useUserProviderNationalIdGetLazyQuery({
    onCompleted: data => {
      if (data?.getClinician) {
        const values = convertUserProviderNationalIdToFormValues(data?.getClinician);
        reset();
        setValues(values);
      }
    },
  });

  const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNationalId(event.target.value);
  };

  const onSearchClick = () => {
    if (!nationalId) return;

    getUserProviderNationalId({
      variables: {
        nationalId,
      },
    });
  };

  return (
    <Box className={classes.root}>
      <TextField value={nationalId} label={t("Search")} placeholder={t("Search")} fullWidth onChange={onTextFieldChange} />

      <Button
        disabled={!nationalId || loading}
        className={classes.button}
        endIcon={loading ? <CircularProgress size={20} /> : null}
        onClick={onSearchClick}
      >
        {t("Search")}
      </Button>
    </Box>
  );
};
