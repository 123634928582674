import { FC, useState } from "react";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { useOptimaEditActivationMutation } from "pages/OptimaEdits/gql";

type OptimaEditActivationProps = {
  id: string;
  isActive: boolean;
};

export const OptimaEditActivation: FC<OptimaEditActivationProps> = props => {
  const { id, isActive } = props;

  const [active, setActive] = useState(isActive);

  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();

  const [activateOptimaEdit, { loading: isSubmitting }] = useOptimaEditActivationMutation({
    onCompleted: mutationData => {
      setActive(!!mutationData?.updateOptimaEditActiveStatus?.isActive);
      succeeded(t("Optima Edit updated successfully"));
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleChangeToggle = () => {
    activateOptimaEdit({ variables: { id } });
  };

  return <CustomToggleButton disabled={isSubmitting} isLoading={isSubmitting} checked={active} onChange={handleChangeToggle} />;
};
