import { isEmpty } from "lodash";

export const safeSetEncodedSearchParam = (paramName: string, value: string | object | undefined | null): URLSearchParams => {
  const params = new URLSearchParams(location.search);
  if (value && !isEmpty(value)) {
    const v = typeof value === "string" ? value : JSON.stringify(value);
    // params.set(paramName, btoa(JSON.stringify(value)));
    params.set(paramName, v);
  } else {
    params.delete(paramName);
  }
  return params;
};

export const safeGetEncodedSearchParam = (paramName: string): object => {
  const params = new URLSearchParams(location.search);

  if (params.get(paramName)) {
    // return params.get(paramName) !== "undefined" ? JSON.parse(atob(params.get(paramName) || "e30=") || "{}") : {};
    return params.get(paramName) !== "undefined" ? JSON.parse(params.get(paramName) || "{}") : {};
  } else {
    return {};
  }
};

export const getSearchParamsVariables = (): {
  after: string | undefined;
  filter: object | undefined;
  sortBy: object | undefined;
} => {
  const params = new URLSearchParams(location.search);
  const defaultFilter = safeGetEncodedSearchParam("filter");
  const defaultSort = safeGetEncodedSearchParam("sort");

  return {
    after: params.get("cursor") || undefined,
    filter: isEmpty(defaultFilter) ? undefined : defaultFilter,
    sortBy: isEmpty(defaultSort) ? undefined : defaultSort,
  };
};
