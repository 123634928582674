import { BranchOrderField, OrderDirection, PermissionEnum } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { branchFieldBreadcrumb, branchListBreadcrumb, branchesTitleBreadcrumb } from "pages/Branches/constants/branches-breadcrumbs";
import { providersManagementPaths } from "pages/ProvidersManagement/constants/providers-management-paths";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProviderCard, useProviderCardHook } from "shared/components";
import { useBranchesDataQuery } from "../gql";
import { BranchesListFilter } from "./BranchesListFilter";
import { useGetBranchesColumns } from "./getBranchesColumns";

export const BranchesListScreen: FC = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const params = useParams();
  const { vendor, loading } = useProviderCardHook();
  const canManageBranches = hasPermission(PermissionEnum.ManageBranches);

  const { t } = useTranslation("admin");
  const handleAddBranch = () => {
    navigate(providersManagementPaths.branchesNewPath.fullPathWithParams(String(params.id)));
  };

  const handleEdit = row => {
    navigate(providersManagementPaths.branchesEditPath.fullPathWithParams(String(params.id), row.id));
  };

  useEffect(() => {
    if (vendor?.name && params?.id) {
      setBreadCrumb({
        title: branchesTitleBreadcrumb(),
        values: [
          branchListBreadcrumb(),
          branchFieldBreadcrumb(vendor.name, providersManagementPaths.editPath.fullPathWithParams(params.id)),
        ],
      });
    }
  }, [vendor?.name, params?.id, t]);

  return (
    <GridProvider
      hasTableSetting
      gridName='branchesList'
      columns={useGetBranchesColumns(canManageBranches)}
      query={useBranchesDataQuery}
      dataAccessor={"branches"}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEdit,
        isRowEditable: canManageBranches,
      }}
      variables={{
        sortBy: {
          direction: OrderDirection.Desc,
          field: BranchOrderField.Created,
        },
      }}
      hideFilterInput
      filterInput={{ vendors: [params.id] }}
    >
      <PageWrapper
        filters={<BranchesListFilter />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew={canManageBranches} onAddNewItem={handleAddBranch} />
          </>
        }
      >
        <ProviderCard loading={loading} vendor={vendor} />
        <Box marginTop={"20px"}>
          <TableGrid />
        </Box>
      </PageWrapper>
    </GridProvider>
  );
};
