export enum PatientEligibilityBenefitsEnum {
  Inpatient = "Inpatient",
  Outpatient = "Outpatient",
  Maternity = "Maternity",
  Dental = "Dental",
  Emergency = "Emergency",
  Optical = "Optical",
  Pharmacy = "Pharmacy",
  Laboratory = "Laboratory",
  Diagnostic = "Diagnostic",
  Radiology = "Radiology",
}
