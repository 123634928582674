import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { usePatientsAutocompleteQuery } from "./gql";
import { IPatientAutocomplete } from "./schema";

export const createPatientAutocompleteOption = (patient: IPatientAutocomplete) => {
  return createAutocompleteOption(
    {
      id: patient?.id!,
      firstName: patient?.firstName,
      lastName: patient?.lastName,
    },
    "id",
    item => `${item?.firstName} ${item?.lastName}`
  );
};

export const getPatientsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "patients",
    query: usePatientsAutocompleteQuery,
    labelBy: option => `${option?.firstName} ${option?.lastName!}`,
    backendAccessor: "id",
    filterSearchKey: "firstName_Icontains",
  });
};
