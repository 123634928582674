import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaEditGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type OptimaEditGetQuery = { __typename?: 'Query', optimaEdit?: { __typename?: 'OptimaEdit', code?: string | null, details?: string | null, message?: string | null, priority?: Types.OptimaPriority | null, source?: string | null, category?: string | null, subCategory?: string | null, isActive?: boolean | null, rejectionCode?: string | null, actions?: Array<{ __typename?: 'OptimaAction', id: string, action?: string | null } | null> | null } | null };


export const OptimaEditGetDocument = gql`
    query OptimaEditGet($id: ID!) {
  optimaEdit(id: $id) {
    code
    details
    message
    priority
    source
    category
    subCategory
    isActive
    rejectionCode
    actions {
      id
      action
    }
  }
}
    `;

/**
 * __useOptimaEditGetQuery__
 *
 * To run a query within a React component, call `useOptimaEditGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaEditGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaEditGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOptimaEditGetQuery(baseOptions: Apollo.QueryHookOptions<OptimaEditGetQuery, OptimaEditGetQueryVariables> & ({ variables: OptimaEditGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaEditGetQuery, OptimaEditGetQueryVariables>(OptimaEditGetDocument, options);
      }
export function useOptimaEditGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaEditGetQuery, OptimaEditGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaEditGetQuery, OptimaEditGetQueryVariables>(OptimaEditGetDocument, options);
        }
export function useOptimaEditGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaEditGetQuery, OptimaEditGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaEditGetQuery, OptimaEditGetQueryVariables>(OptimaEditGetDocument, options);
        }
export type OptimaEditGetQueryHookResult = ReturnType<typeof useOptimaEditGetQuery>;
export type OptimaEditGetLazyQueryHookResult = ReturnType<typeof useOptimaEditGetLazyQuery>;
export type OptimaEditGetSuspenseQueryHookResult = ReturnType<typeof useOptimaEditGetSuspenseQuery>;
export type OptimaEditGetQueryResult = Apollo.QueryResult<OptimaEditGetQuery, OptimaEditGetQueryVariables>;