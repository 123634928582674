import { OptimaEdit } from "@/schema/types";
import { getAutocompleteEnumFilter, priorityOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { OptimaEditActivation } from "pages/OptimaEdits/components/OptimaEditActivation/OptimaEditActivation";
import { OptimaEditActionsModal } from "pages/OptimaEdits/modals/OptimaEditActions/OptimaEditActionsModal";
import { useMemo } from "react";

export const useOptimaEditListContainerColumns = (): CustomTableColumnProps<OptimaEdit>[] => {
  const { t } = useTranslation("domains");

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
      },
      {
        key: "category",
        header: t("Category"),
        accessor: ({ category }) => category,
      },
      {
        key: "subCategory",
        header: t("Sub Category"),
        accessor: ({ subCategory }) => subCategory,
      },
      {
        key: "rejectionCode",
        header: t("Rejection Code"),
        accessor: ({ rejectionCode }) => rejectionCode,
      },
      {
        key: "priority",
        header: t("Priority"),
        accessor: ({ priority }) => priorityOptionsMap[priority!]?.label,
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: ({ actions }) => <OptimaEditActionsModal actions={actions} />,
      },
      {
        key: "active",
        header: t("Active"),
        accessor: row => <OptimaEditActivation id={row.id} isActive={!!row?.isActive} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, [t]);
};
