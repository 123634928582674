import { IAutocompleteProps } from "@/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { H_CancelReason, ReasonFilterInput } from "../../schema/types";
import { useCancellationReasonsAutocompleteQuery } from "./gql";

type CancellationReasonsAutocompleteProps = IAutocompleteProps<ReasonFilterInput>;

export const CancellationReasonsAutocomplete: FC<CancellationReasonsAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, loading } = useCancellationReasonsAutocompleteQuery({
    variables: { filter },
    skip,
  });

  const cancellationReasons = (data?.cancellationReasons || []) as H_CancelReason[];

  const _data = cancellationReasons?.length ? cancellationReasons : [{ id: "other", text: t("Other"), text_ar: t("Other") }];

  const options = mapToAutocompleteOptions(_data, "id", item => pickLocalizedValue(item?.text!, item?.text_ar!));

  return (
    <FormAutocomplete
      {...rest}
      loading={loading}
      options={options}
      label={label || (props.multiple ? t("Cancellation Reasons") : t("Cancellation Reason"))}
    />
  );
};
