import { i18n } from "@toolkit/i18n";
import { IEnumToOptionsMap } from "../../types";
import { OrderType } from "../../schema/types";

export const deliveryMethodTypeMap: IEnumToOptionsMap<OrderType> = {
  [OrderType.Delivery]: {
    key: OrderType.Delivery,
    get label() {
      return i18n.t("Delivery", { ns: "domains" });
    },
    value: OrderType.Delivery,
  },
  [OrderType.Pickup]: {
    key: OrderType.Pickup,
    get label() {
      return i18n.t("Pickup", { ns: "domains" });
    },
    value: OrderType.Pickup,
  },
};

export const deliveryMethosTypeOptions = Object.values(deliveryMethodTypeMap);
