import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancellationReasonsAutocompleteQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ReasonFilterInput>;
}>;


export type CancellationReasonsAutocompleteQuery = { __typename?: 'Query', cancellationReasons?: Array<{ __typename?: 'H_CancelReason', id: string, text?: string | null, text_ar?: string | null } | null> | null };


export const CancellationReasonsAutocompleteDocument = gql`
    query CancellationReasonsAutocomplete($filter: ReasonFilterInput) {
  cancellationReasons(filter: $filter) {
    id
    text
    text_ar
  }
}
    `;

/**
 * __useCancellationReasonsAutocompleteQuery__
 *
 * To run a query within a React component, call `useCancellationReasonsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancellationReasonsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancellationReasonsAutocompleteQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCancellationReasonsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>(CancellationReasonsAutocompleteDocument, options);
      }
export function useCancellationReasonsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>(CancellationReasonsAutocompleteDocument, options);
        }
export function useCancellationReasonsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>(CancellationReasonsAutocompleteDocument, options);
        }
export type CancellationReasonsAutocompleteQueryHookResult = ReturnType<typeof useCancellationReasonsAutocompleteQuery>;
export type CancellationReasonsAutocompleteLazyQueryHookResult = ReturnType<typeof useCancellationReasonsAutocompleteLazyQuery>;
export type CancellationReasonsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useCancellationReasonsAutocompleteSuspenseQuery>;
export type CancellationReasonsAutocompleteQueryResult = Apollo.QueryResult<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>;