/* eslint-disable max-lines */
import { Vendor, VendorOrderField } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { getMediaLink } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Avatar, Box, CustomTableColumnProps, format, LazyInfoCell, Tooltip, TruncateTypography, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { IsActiveToggleButton } from "./ActiveToggle.component";
import { SupportOutpatientJourney } from "./SupportOutpatientJourney.component";
import TerminateVendor from "./TerminateVendor.list";
import { getVendorType } from "./VendorList.types";
import { getBankingInfoItems, getOwnerInfoItems, getWorkingHoursInfoGroup } from "../../others/cellInfo.utils";
import { getAddressesInfoItems } from "@/shared/utils/CellInfo.utils";
import { providersManagementPaths } from "../../constants/providers-management-paths";
import { useNavigate } from "react-router-dom";
import { useSiteSettingsGetQuery, useVendorBranchesLazyQuery } from "@/gql/queries";

export const useVendorsColumns = (): CustomTableColumnProps<Vendor>[] => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { data: setting } = useSiteSettingsGetQuery();
  const timeZone = setting?.siteSettings?.timeZone! || 3;

  return useMemo(() => {
    return [
      {
        key: "avatar",
        header: t("Logo"),
        accessor: ({ logo }) => <Avatar src={getMediaLink(logo!)} />,
        isHidden: false,
        settings: {
          hideFromSettings: true,
          disableToggleVisibility: true,
        },
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name }) => <TruncateTypography text={name} />,
        isHidden: false,
        sort: {
          columnEnum: VendorOrderField.Name,
        },
        settings: {
          hideFromSettings: true,
          disableToggleVisibility: true,
        },
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "description",
        header: t("Description"),
        accessor: row => {
          const des = row?.description?.split("\\n") || [];

          return (
            <Box sx={{ width: "250px" }}>
              <Tooltip title={row?.description ?? ""}>
                <span>
                  {des?.map(item => (
                    <Typography noWrap key={item} component={"div"}>
                      {item}
                    </Typography>
                  ))}
                </span>
              </Tooltip>
            </Box>
          );
        },
        isHidden: false,
      },
      {
        key: "nationalId_Icontains",
        header: t("National Id"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "nationalId_Icontains",
        },
      },
      {
        key: "ownerName_Icontains",
        header: t("Owner Name"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "ownerName_Icontains",
        },
      },
      {
        key: "deliveryPrice",
        header: t("Delivery Price"),
        accessor: "deliveryPrice",
      },
      {
        key: "created",
        header: t("Created"),
        accessor: row => <TruncateTypography text={format(row?.created, "DD MMM YYYY, h:mm A")} />,
        isHidden: false,
        sort: {
          columnEnum: VendorOrderField.Created,
        },
      },
      {
        key: "address",
        header: t("Address"),
        type: "info",
        infoCellOptions: {
          title: t("Address information"),
          items: ({ address }) => getAddressesInfoItems(address),
          emptyMessage: t("There is No Address Information"),
        },
        isHidden: false,
      },
      {
        key: "branches",
        header: t("Branches"),
        type: "redirect",
        redirectCellOptions: {
          onClick: ({ id, name }) =>
            navigate(providersManagementPaths.branchesPath.fullPathWithParams(id), { state: { providerName: name } }),
          tooltipMessage: row => t("show {{name}} branches", { name: pickLocalizedValue(row?.name, row.nameAr) }),
        },
        isHidden: false,
      },
      {
        key: "users",
        header: t("Users"),
        type: "redirect",
        redirectCellOptions: {
          link: ({ id }) => providersManagementPaths.usersPath.fullPathWithParams(id),
          tooltipMessage: row => t("show {{name}} users", { name: pickLocalizedValue(row?.name, row.nameAr) }),
        },
        isHidden: false,
      },
      {
        key: "bankingInfo",
        header: t("Banking Info"),
        type: "info",
        infoCellOptions: {
          title: t("Banking information"),
          items: ({ bankInfo }) => getBankingInfoItems(bankInfo),
          emptyMessage: t("There is No Banking Information"),
        },
        isHidden: false,
      },
      {
        key: "workingHours",
        header: t("Working Hours"),
        accessor: row => (
          <LazyInfoCell
            queryOptions={{
              useCustomLazyQuery: useVendorBranchesLazyQuery,
              variables: row => ({ id: row.id, first: 100 }),
              processor: data => data.vendor?.branches?.edges?.map(edge => edge?.node) ?? [],
            }}
            cellInfoOptions={{
              row,
              title: t("Address Information"),
              groups: (_, nodes = []) => nodes?.map(node => getWorkingHoursInfoGroup(node, timeZone)),
              emptyMessage: t("There is No Address Information"),
            }}
          />
        ),
        isHidden: false,
      },
      {
        key: "ownerInfo",
        header: t("Owner Info"),
        type: "info",
        infoCellOptions: {
          title: t("Owner Information"),
          items: ({ ownerName, nationalId }) =>
            getOwnerInfoItems({
              ownerName,
              nationalId,
            }),
          emptyMessage: t("There is No Owner Information"),
        },
        isHidden: false,
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => t(getVendorType(type)),
      },
      {
        key: "isActive",
        header: t("Is Active"),
        accessor: row => <IsActiveToggleButton id={row?.id} isActive={row?.isActive} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
      {
        key: "terminate",
        header: t("Terminate"),
        accessor: row => row.approved && <TerminateVendor id={row?.id} isActive={row?.isActive} />,
        isHidden: false,
        settings: {
          hideFromPreferencesAndTable: true,
          disableToggleVisibility: false,
        },
      },
      {
        key: "supportOutpatientJourney",
        header: t("Supports Out Patient Journey"),
        accessor: ({ supportOutpatientJourney, id }) => (
          <SupportOutpatientJourney id={id} supportOutpatientJourney={supportOutpatientJourney} />
        ),
      },
      {
        key: "vendorTypeFilter",
        header: t("Vendor Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("VendorType", "type"),
      },
    ];
  }, [timeZone]);
};
