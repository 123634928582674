import React, { FC } from "react";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useDecisionChronicTemplatesAutocompleteQuery } from "pages/Decisions/gql";

type DecisionChronicTemplatesAutocompleteProps = {
  name: string;
  label?: string;
};

export const DecisionChronicTemplatesAutocomplete: FC<DecisionChronicTemplatesAutocompleteProps> = props => {
  const { name, label } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useDecisionChronicTemplatesAutocompleteQuery,
    searchKey: "name_Icontains",
  });

  const options = mapToAutocompleteOptions(data, "id", "name");

  return <FormAutocomplete {...restRelayStyleConnection} name={name} options={options} label={label || t("Template")} />;
};
