import { Decision, DecisionMakerOrderDirection, DecisionPlanCategory, DecisionSortField } from "@/schema/types";
import { getDecisionPlansAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { DecisionActivation } from "pages/Decisions/components";
import { useMemo } from "react";

type DecisionListTableColumnsProps = {
  category: DecisionPlanCategory;
};

export const useDecisionListTableColumns = ({ category }: DecisionListTableColumnsProps): CustomTableColumnProps<Decision>[] => {
  const { t } = useTranslation("domains");

  return useMemo(() => {
    return [
      {
        key: "Number",
        header: t("Rule Name"),
        accessor: ({ name }) => name,
        type: "string",
        disableToggleVisibility: true,
        sort: {
          direction: DecisionMakerOrderDirection.Asc,
          columnEnum: DecisionSortField.Name,
        },
        filter: {
          type: "string",
          name: "decisionName",
        },
      },
      {
        key: "plan",
        header: t("Decision Plan"),
        accessor: ({ decisionPlan }) => t(decisionPlan?.display!),
        type: "string",
        sort: {
          columnEnum: DecisionSortField.DecisionPlan,
        },
        filter: getDecisionPlansAutocompleteFilter({ name: "plan", filter: { categories: [category] } }),
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ isScript }) => (isScript ? t("Script") : t("Dynamic")),
        type: "string",
        settings: {
          disableToggleVisibility: true,
        },
        sort: {
          columnEnum: DecisionSortField.Type,
        },
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <DecisionActivation id={id!} isActive={!!isActive} />,
        type: "string",
        settings: {
          hideFromSettings: true,
        },
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, []);
};
