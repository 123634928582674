import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, ShowButton } from "@toolkit/ui";
import { FC } from "react";
import { CredentialForm } from "./CredentialForm";
import { credentialType } from "./type";
import { useCredentialDialog } from "./useCredentialDialog";

type CredentialDIalogProps = {
  targetId: string;
  type: credentialType;
  title: string;
  isDisabled: boolean;
};
export const CredentialDialog: FC<CredentialDIalogProps> = props => {
  const { targetId, type, title, isDisabled } = props;
  const { t } = useTranslation("domains");
  const { open, handleOpen, handleClose, isLoading, isSubmitting, credential, form, onSubmit, handleSubmit } = useCredentialDialog({
    targetId,
    type,
    t,
  });

  return (
    <CustomDialog
      type='base'
      open={open}
      DialogTitleProps={{
        onClose: handleClose,
        title,
      }}
      DialogContentProps={{
        sx: { minHeight: "unset" },
      }}
      button={<ShowButton disabled={isDisabled} onClick={handleOpen} />}
      DialogActionsProps={{
        children: (
          <>
            <Button
              color={"primary"}
              onClick={handleSubmit}
              type='submit'
              form='custom-dialog-form'
              size='large'
              variant='contained'
              disabled={isLoading || isSubmitting}
            >
              {t("Save")}
            </Button>
            <Button variant='contained' size='large' color={"inherit"} onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </>
        ),
      }}
    >
      <CredentialForm credential={credential} isLoading={isLoading} isSubmitting={isSubmitting} onSubmit={onSubmit} form={form} />
    </CustomDialog>
  );
};
