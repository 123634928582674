import React, { FC, useRef } from "react";
import { Tooltip, Typography, type TypographyProps } from "../../base/mui";
import { useTruncateTypographyStyles } from "./TruncateTypography.styles";

type BaseTruncateProps = {
  text: string;
  maxLength?: number;
  maxLines?: number;
} & Omit<TypographyProps, "children">;

export const TruncateTypography: FC<BaseTruncateProps> = ({ text, maxLength, maxLines, maxWidth = 250, className, ...restOfProps }) => {
  const { classes, cx } = useTruncateTypographyStyles({ maxWidth, maxLines, maxLength });
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    const element = ref.current!;

    if (maxLength) {
      setOpen(text.length > maxLength);
    } else if (maxLines) {
      const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
      const maxHeight = lineHeight * maxLines;
      setOpen(element.scrollHeight > maxHeight);
    }
    if (maxWidth) {
      setOpen(element.scrollWidth > element.clientWidth);
    }
  };

  const getClasses = () => {
    return cx(
      classes.base,
      {
        [classes.characterTruncate]: !!maxLength,
        [classes.widthTruncate]: !maxLength,
        [classes.rowTruncate]: !!maxLines,
      },
      className
    );
  };

  return (
    <Tooltip title={text} open={open} onClose={handleClose} onOpen={handleOpen}>
      <Typography ref={ref} className={getClasses()} {...restOfProps}>
        {text}
      </Typography>
    </Tooltip>
  );
};
