import { OptimaAction, OptimaActionInput } from "@/schema/types";
import { IOptimaActionUpsertFormValues } from "pages/OptimaActions/forms/OptimaActionUpsert/OptimaActionUpsertFormSchema";

export const convertOptimaActionToFormValues = (optimaAction: OptimaAction): IOptimaActionUpsertFormValues => {
  return {
    action: optimaAction?.action!,
    link: optimaAction?.link!,
    summary: optimaAction?.summary!,
    isActive: !!optimaAction?.isActive,
  };
};

export const convertOptimaActionFormValuesToBackEndValues = (values: IOptimaActionUpsertFormValues): OptimaActionInput => {
  return {
    action: values?.action,
    link: values?.link,
    summary: values?.summary,
    isActive: values?.isActive,
  };
};
