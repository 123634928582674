import { Plan, VendorAdminInput } from "@/schema/types";
import { IGoogleMapAddress } from "@toolkit/maps";
import { removeEmptyRanges, useAutocompleteCustomHook } from "@toolkit/ui";
import { PlansQueryVariables, usePlansQuery } from "gql/queries";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export const useVendorForm = vendor => {
  const [location, setLocation] = useState({
    lat: 24.4961134,
    lng: 54.3798649,
  });

  const methods = useForm<VendorAdminInput>({
    mode: "all",
    criteriaMode: "all",
  });

  const {
    setValue,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = methods;

  const hasMultipleBranches = !watch("hasMultipleBranches");

  useEffect(() => {
    vendor?.address?.coordinates && setLocation(vendor?.address?.coordinates);
  }, [vendor?.address?.coordinates]);

  const dataAccessorForPlans = "plans";
  const inputs: PlansQueryVariables = {
    first: 10,
    after: null,
    before: null,
    last: null,
    filter: { isActive: true },
  };
  const {
    data: plansResponse,
    isLoading: loadingPlans,
    onFetchMoreData,
    refetch,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: usePlansQuery,
    input: inputs,
    dataAccessor: dataAccessorForPlans,
  });
  const pageInfoForPlans = plansResponse?.[dataAccessorForPlans]?.pageInfo;
  const hasMoreForPlans = pageInfoForPlans?.hasNextPage;
  const plans = plansResponse?.[dataAccessorForPlans]?.edges.map(item => item?.node)! as Plan[];

  const handleLocationChange = (location: IGoogleMapAddress["coordinates"]) => setLocation(location);

  const handleFetchMoreDataForPlans = () => {
    hasMoreForPlans && onFetchMoreData?.();
  };

  const onSubmit = data => {
    const {
      name,
      arabicName,
      type,
      addressInput,
      arabicStreetAddress,
      arabicStreetAddress2,
      subscription,
      bankInfo,
      hasMultipleBranches,
      healthLicenseStartDate,
      healthLicenseEndDate,
      branchWorkingHours,
      ...rest
    } = data;

    const { city, streetAddress1, area, ...addressInputRest } = addressInput;
    const bankInfoObject = bankInfo?.bankName?.value
      ? {
          bankInfo: {
            ...bankInfo,
            bankName: bankInfo?.bankName?.value,
          },
        }
      : {
          bankInfo: {
            accountNumber: null,
            iban: null,
            accountName: null,
            bankName: null,
          },
        };
    return {
      ...rest,
      name: name,
      nameAr: arabicName,
      type: type.value,
      hasMultipleBranches: hasMultipleBranches,
      addressInput: {
        ...addressInputRest,
        city: city?.value?.id,
        area,
        coordinates: {
          lat: location?.lat,
          lng: location?.lng,
        },
        streetAddress2Ar: arabicStreetAddress2,
        streetAddress1Ar: arabicStreetAddress,
        streetAddress1,
        streetAddress2: addressInput.streetAddress2,
      },
      subscription: {
        ...subscription,
        plan: subscription.plan?.plan?.id || subscription.plan.id,
      },
      ...bankInfoObject,
      healthLicenseStartDate:
        typeof healthLicenseStartDate == "string" ? healthLicenseStartDate : healthLicenseStartDate?.toISOString().substring(0, 10),
      healthLicenseEndDate:
        typeof healthLicenseEndDate == "string" ? healthLicenseEndDate : healthLicenseEndDate?.toISOString().substring(0, 10),

      branchWorkingHours: hasMultipleBranches ? [] : removeEmptyRanges(branchWorkingHours),
    };
  };
  const handleSearchInput = name => {
    refetch({
      filter: {
        name_Icontains: name,
        isActive: true,
      },
      first: 10,
    });
  };

  return {
    plans,
    location,
    loadingPlans,
    hasMoreForPlans,
    handleFetchMoreDataForPlans,
    isDirty,
    methods,
    setValue,
    hasMultipleBranches,
    onSubmit,
    handleSubmit,
    handleLocationChange,
    handlePlanSearchInput: handleSearchInput,
  };
};
