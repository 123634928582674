import { omit } from "lodash";
import { FC, PropsWithChildren } from "react";
import { CustomDialog } from "../../../Dialogs";
import { CustomInfoDialogProps } from "../../../Dialogs/InfoDialog";
import { InfoDrawer, InfoDrawerProps } from "../InfoDrawer";

interface CommonProps {
  title?: string;
  disableShowButton?: boolean;
  onShowButtonClicked?: () => void;
}

interface BaseDialogProps extends Partial<Omit<CustomInfoDialogProps, "children" | "title" | "disabledShowButton">>, CommonProps {
  variant?: "dialog";
}

interface BaseDrawerProps extends Partial<Omit<InfoDrawerProps, "children" | "variant" | "title">>, CommonProps {
  variant: "drawer";
}

export type BaseDetailsViewerProps = BaseDialogProps | BaseDrawerProps;

export const BaseDetailsViewer: FC<PropsWithChildren<BaseDetailsViewerProps>> = props => {
  if (props.variant === "drawer") {
    const drawerProps = props as BaseDrawerProps;
    return <InfoDrawer {...omit(drawerProps, "variant")}>{props.children}</InfoDrawer>;
  }

  const dialogProps = props as BaseDialogProps;
  return (
    <CustomDialog
      title={props.title || "-"}
      disabledShowButton={props.disableShowButton}
      onShowButtonClicked={props.onShowButtonClicked}
      type='info'
      {...omit(dialogProps, ["variant", "title"])}
    >
      {props.children}
    </CustomDialog>
  );
};
