import { useGetFeatureFlagQuery } from "./gql/queries";

export enum FeatureEnum {
  HealthPackage = "HealthPackage",
  Marketplace = "Marketplace",
}

const FeatureEnumMap = {
  [FeatureEnum.HealthPackage]: "healthPackageFeatureEnabled",
  [FeatureEnum.Marketplace]: "marketplaceFeatureEnabled",
};

export type IsFeatureEnabledType = (features: Array<FeatureEnum>) => boolean;

export const useFeatureFlag = () => {
  const { data, loading } = useGetFeatureFlagQuery();
  const featureFlag = data?.featureFlag;

  // const featureFlag = {
  //   healthPackageFeatureEnabled: true,
  //   marketplaceFeatureEnabled: true,
  // };

  const isFeatureEnabled: IsFeatureEnabledType = (features: Array<FeatureEnum>) => {
    if (loading) {
      return false;
    }
    return features.length ? features.some(feature => featureFlag?.[FeatureEnumMap[feature]]) : false;
  };

  return {
    isFeatureEnabled,
  };
};
