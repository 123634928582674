import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { GridProvider } from "shared/components";
import { FC } from "react";
import { useOrdersInvoicesColumns } from "./useOrdersInvoicesColumns";
import { useGetPaymentInvoicesQuery } from "../gql";
import { ReportDownload } from "../components/ReportDownload";

export const OrdersInvoicesPage: FC<{ canDownloadReport?: boolean }> = ({ canDownloadReport }) => {
  return (
    <GridProvider gridName='orders-invoices' columns={useOrdersInvoicesColumns()} query={useGetPaymentInvoicesQuery} hasTableSetting>
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            {canDownloadReport && (
              <MainListActions>
                <ReportDownload />
              </MainListActions>
            )}
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
