import { AppTypes, OrderDirection, PermissionEnum, UserSortField } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useUserProviderListQuery, useUserDeleteMutation } from "../../gql";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import { IUserProviderListNode } from "../../types";
import { useUserProviderListContainerColumns } from "./useUserProviderListContainerColumns";
import { FC } from "react";

export type IUserProviderListContainerEvent =
  | {
      type: "ADD_NEW_CLICK";
    }
  | {
      type: "EDIT_CLICK";
      payload: {
        item: IUserProviderListNode;
      };
    };

type UserProviderListContainerProps = {
  onChange: (event: IUserProviderListContainerEvent) => void;
};

export const UserProviderListContainer: FC<UserProviderListContainerProps> = props => {
  const { onChange } = props;

  const hasManageUsersPermission = hasPermission(PermissionEnum.ManageUsers);

  const onAddNewItemClick = () => {
    onChange({
      type: "ADD_NEW_CLICK",
    });
  };

  const onEditRowClick = (item: IUserProviderListNode) => {
    onChange({
      type: "EDIT_CLICK",
      payload: { item },
    });
  };

  return (
    <GridProvider
      gridName={"userProviderList"}
      query={useUserProviderListQuery}
      columns={useUserProviderListContainerColumns()}
      hideFilterInput
      hasTableSetting
      variables={{
        filter: {
          appType: [AppTypes.Vendor],
        },
        sortBy: {
          field: UserSortField.DateJoined,
          direction: OrderDirection.Desc,
        },
      }}
      tableAction={{
        isEditVisible: hasManageUsersPermission,
        onEditRow: onEditRowClick,
        isDeleteVisible: hasManageUsersPermission,
      }}
      deleteItemProps={{
        entityTypeName: "User",
        name: "User Provider",
        useDeleteMutation: useUserDeleteMutation,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />

            {hasManageUsersPermission && <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />}
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
