import { useCallback, useState } from "react";
import { useGetVendorLikeDislikeEnabledQuery, useLikeDislikeItemEditMutation } from "../gql";
import { LikeDislike } from "@/schema/types";

export const useOptimaVisitValidationActions = () => {
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);

  const [idsBeingUpdated, setIdsBeingUpdated] = useState<string[]>([]);

  const [fetchLikeDislikeItemEdit] = useLikeDislikeItemEditMutation();

  const { data: getVendorData } = useGetVendorLikeDislikeEnabledQuery();

  const onSetLikeStatus = useCallback(
    (id: string, likeStatus: LikeDislike) => {
      setIdsBeingUpdated(ids => [...ids, id]);
      fetchLikeDislikeItemEdit({
        variables: { id: id!, likeDislike: likeStatus },
      }).finally(() => {
        setIdsBeingUpdated(ids => ids.filter(i => i !== id));
      });
    },
    [fetchLikeDislikeItemEdit]
  );

  const onLikeClick = useCallback(
    id => () => {
      onSetLikeStatus(id, LikeDislike.Like);
    },
    [onSetLikeStatus]
  );

  const onDisLikeClick = useCallback(
    id => () => {
      onSetLikeStatus(id, LikeDislike.Dislike);
    },
    [onSetLikeStatus]
  );

  const checkEditBeingUpdated = (id?: string) => idsBeingUpdated.includes(id || "");
  const handleToggleCollapse = (id: string) => {
    setCollapsedItems(prevState => (prevState.includes(id) ? prevState.filter(collapsedId => collapsedId !== id) : [...prevState, id]));
  };

  const isLikeDislikeEnabled = getVendorData?.me?.vendor?.editLikeEnabled;

  return {
    collapsedItems,
    idsBeingUpdated,
    isLikeDislikeEnabled,
    fetchLikeDislikeItemEdit,
    onSetLikeStatus,
    onLikeClick,
    onDisLikeClick,
    checkEditBeingUpdated,
    handleToggleCollapse,
  };
};
