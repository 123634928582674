import { formGirdBreakPoints } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const SubscriptionPlan: FC<{ plans; defaultValues; onFetchMoreDataForPlans; hasMoreForPlans; handlePlanSearchInput }> = ({
  plans,
  defaultValues,
  onFetchMoreDataForPlans,
  hasMoreForPlans,
  handlePlanSearchInput,
}) => {
  const { t } = useTranslation("admin");
  const {
    control,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext();

  const selectedPlan = defaultValues?.subscriptions && plans?.find(plan => plan.id === defaultValues?.subscriptions?.plan?.id);
  const editDefaultValues = defaultValues?.subscriptions && {
    ...selectedPlan,
    plan: defaultValues?.subscriptions?.plan,
    fixedCostAmount: defaultValues?.subscriptions?.fixedCostAmount,
    fixedOrderCostAmount: defaultValues?.subscriptions?.fixedOrderCostAmount,
    fixedOrderPercentage: defaultValues?.subscriptions?.fixedOrderPercentage,
  };

  useEffect(() => {
    setValue("subscription.plan", editDefaultValues || null);
  }, [JSON.stringify(editDefaultValues), defaultValues?.subscriptions]);

  return (
    <Grid item {...formGirdBreakPoints}>
      <AutocompleteController
        ControllerProps={{
          name: "subscription.plan",
          control: control,
          defaultValue: editDefaultValues || null,
        }}
        fetchMoreData={onFetchMoreDataForPlans}
        hasMore={hasMoreForPlans}
        getOptionLabel={option => option?.name}
        options={plans || []}
        TextFieldProps={{
          placeholder: t("Subscription plan"),
          label: t("Subscription plan"),
          error: Boolean(formErrors?.subscription?.message),
          helperText: String(formErrors?.subscription?.message || ""),
        }}
        onSearchInput={handlePlanSearchInput}
      />
    </Grid>
  );
};
