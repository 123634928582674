import { Branch } from "@/schema/types";
import { getWorkingHoursWithTimeZone } from "@/shared/components";
import { i18n, pickLocalizedValue } from "@toolkit/i18n";
import { formatTime, getDayString, InfoGroup } from "@toolkit/ui";
import { isEmpty } from "lodash";

export const getWorkingHoursInfoGroup = (branch: Branch, timeZone = 3): InfoGroup => {
  const { workingHours, name, nameAr } = branch;
  const getWorkingHours = getWorkingHoursWithTimeZone(workingHours, timeZone);

  const headerItems = !isEmpty(getWorkingHours)
    ? [
        { label: i18n.t("Day", { ns: "admin" }), value: "" },
        { label: i18n.t("Start Time", { ns: "admin" }), value: "" },
        { label: i18n.t("End Time", { ns: "admin" }), value: "" },
      ]
    : [
        {
          value: i18n.t("No Working Hours Provided", { ns: "admin" }),
        },
      ];

  const workingHourItems =
    getWorkingHours?.flatMap(workingHour => {
      const dayName = i18n.t(getDayString(String(workingHour.day)), { ns: "admin" });

      if (workingHour.openTimeRanges.length === 1) {
        const timeRange = workingHour.openTimeRanges[0];
        return [
          { label: dayName, direction: "row" },
          { value: formatTime(timeRange.openTime, i18n.language) },
          { value: formatTime(timeRange.closeTime, i18n.language) },
        ];
      }

      return [
        {
          label: dayName,
          direction: "row",
        },
        {
          value: workingHour.openTimeRanges.map(range => formatTime(range.openTime, i18n.language)),
          valueDisplayMode: "stacked",
        },
        {
          value: workingHour.openTimeRanges.map(range => formatTime(range.closeTime, i18n.language)),
          valueDisplayMode: "stacked",
        },
      ];
    }) || [];

  return {
    title: pickLocalizedValue(name, nameAr)
      ? i18n.t("Workings hours for {{name}}", { name: pickLocalizedValue(name, nameAr) })
      : i18n.t("Branch Working Hours", { ns: "admin" }),
    variant: "accordion",
    layout: "three-columns",
    items: [...headerItems, ...workingHourItems],
  };
};
