import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomIcon, IconButton, useAddToast, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { MarketplaceOrderStatus, Maybe } from "../../../../schema/types";
import { IOrderCancelFormEvent } from "../../forms/OrderCancel/OrderCancelForm";
import { useMarketplaceOrderCancelBySupportTeamMutation } from "../../gql";
import { OrderCancelModal, OrderCancelModalApi } from "../../modals/OrderCancel/OrderCancelModal";

type OrderCancelProps = {
  id: string;
  status?: Maybe<MarketplaceOrderStatus>;
};

export const OrderCancel: FC<OrderCancelProps> = props => {
  const { id, status } = props;

  const { t } = useTranslation("domains");
  const theme = useTheme();
  const { succeeded, failed } = useAddToast();

  const onIconButtonClick = () => {
    if (status === MarketplaceOrderStatus.Cancelled) return;

    OrderCancelModalApi.open({ id });
  };

  const [cancelMarketplaceOrder, { loading: isSubmitting }] = useMarketplaceOrderCancelBySupportTeamMutation({
    onCompleted: mutationData => {
      if (mutationData?.marketplaceOrderCancelBySupportTeam?.id) {
        succeeded(t("Order canceled successfully"));
        OrderCancelModalApi.close();
      } else {
        failed(t("Order cancellation failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update: (cache, { data }) => {
      const normalizedId = cache.identify({ id: data?.marketplaceOrderCancelBySupportTeam?.id, __typename: "MarketplaceOrder" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const onOrderCancelModalChange = (event: IOrderCancelFormEvent) => {
    if (event.type === "SUBMIT") {
      const { reason, otherReason } = event.payload.values;

      const reasonValue = reason?.value;

      cancelMarketplaceOrder({
        variables: {
          id: event.payload.id,
          cancellationReason: reasonValue?.text?.toLowerCase() === "other" ? otherReason! : reasonValue?.text!,
        },
      });
    }
  };

  return (
    <Box>
      <OrderCancelModal isLoading={isSubmitting} onChange={onOrderCancelModalChange} />

      <IconButton disabled={status === MarketplaceOrderStatus.Cancelled} onClick={onIconButtonClick}>
        <CustomIcon icon={"miniClose"} height={30} width={30} viewBox={"0 5 20 10"} color={theme.palette.error.main} />
      </IconButton>
    </Box>
  );
};
