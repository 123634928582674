import { SubListItemInput } from "@/schema/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { fromBase64 } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, PageWrapper, useToasts } from "@toolkit/ui";
import { SublistItemUpsertForm } from "pages/Sublists/forms";
import { useSublistItemUpdateMutation } from "pages/Sublists/gql";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryString, useReportAnError } from "shared/hooks";

export const SublistItemUpdateContainer: FC = () => {
  const { t } = useTranslation("domains");
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const { reportGraphQlErrors } = useReportAnError();
  const query = useQueryString();

  const [fetchSaveItem, { loading: isSubmitting }] = useSublistItemUpdateMutation();
  const { itemId } = useParams();

  const item = JSON.parse(fromBase64(query.get("item") || ""));

  const methods = useForm<SubListItemInput>({
    mode: "all",
  });

  const addItem = (input: SubListItemInput) => {
    fetchSaveItem({
      variables: {
        subListItem: {
          id: itemId,
          code: input.code,
          display: input.display,
        },
      },
      onCompleted: e => {
        if (e.updateSubListItem?.errors?.length) {
          addToast(t("Failed to update item") + "\n" + formatMessageErrors(e.updateSubListItem?.errors), {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
        addToast(t("Item updated successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        navigate(-1);
      },
      onError: ({ graphQLErrors }) => reportGraphQlErrors(t("Failed to update sublist item"))(graphQLErrors),
    });
  };

  const handleSaveListItem = methods.handleSubmit(addItem);

  return (
    <PageWrapper
      actions={
        <FormActions
          hasSave
          hasCancel
          onSave={handleSaveListItem}
          loadingIndicators={{ save: isSubmitting }}
          isSaveDisabled={isSubmitting}
        />
      }
    >
      <FormCard loading={false} title={t("Edit item")} doYouHaveData={true}>
        <FormProvider {...methods}>
          <SublistItemUpsertForm onHandleSubmit={handleSaveListItem} defaults={item} />
        </FormProvider>
      </FormCard>
    </PageWrapper>
  );
};
