import { Group, PermissionGroupSortField } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, formatDate } from "@toolkit/ui";
import { useMemo } from "react";
import { getPermissionsInfoItems } from "../../others/cellInfo.utils";

export const useUserPermissionGroupListContainerColumns = (): CustomTableColumnProps<Group>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        type: "truncated-text",
        accessor: "name",
        sort: {
          columnEnum: PermissionGroupSortField.Name,
        },
      },
      {
        key: "permissions",
        header: t("Permissions"),
        type: "info",
        infoCellOptions: {
          title: t("Permissions"),
          items: ({ permissions }) => getPermissionsInfoItems(permissions),
          emptyMessage: t("No permissions"),
          layout: "one-column",
          direction: "column",
        },
      },
      {
        key: "created",
        header: t("Created Date"),
        accessor: ({ created }) => formatDate(created, "DD MMM YYYY"),
        sort: { columnEnum: PermissionGroupSortField.CreatedDate },
      },
      {
        key: "search",
        header: t("Search"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "isEditable",
        header: t("Is Editable"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "isEditable"),
      },
      {
        key: "isGlobal",
        header: t("Is Global"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "isGlobal"),
      },
    ],
    [t]
  );
};
