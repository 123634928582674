import { makeStyles } from "../../../../base/mui";

export const useTableCellRendererStyles = makeStyles()(theme => ({
  commonText: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  typographyCell: {
    width: "max-content",
  },
  dateCell: {
    width: "max-content",
    maxWidth: "max-content",
  },
}));
