import { OptimaAction } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { OptimaActionActivation } from "pages/OptimaActions/components/OptimaActionActivation/OptimaActionActivation";
import { useMemo } from "react";

export const useOptimaActionListContainerColumns = (): CustomTableColumnProps<OptimaAction>[] => {
  const { t } = useTranslation("domains");

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("ID"),
        accessor: "id",
      },
      {
        key: "action",
        header: t("Action"),
        accessor: "action",
      },
      {
        key: "link",
        header: t("Link"),
        accessor: "link",
      },
      {
        key: "active",
        header: t("Active"),
        accessor: row => <OptimaActionActivation id={row.id} isActive={!!row?.isActive} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, [t]);
};
