import { useProviderUserDeleteMutation } from "@/pages/ProviderUsers/gql";
import { UserSortField } from "@/schema/types";
import { useUsersColumns } from "@/shared/components/Users/useUsersColumns";
import { GridProvider } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import {
  Box,
  FilterGrid,
  GridContext,
  MainListActions,
  OrderDirection,
  PageWrapper,
  TableGrid,
  TableSettingComponent,
  useBreadCrumbs,
} from "@toolkit/ui";
import { useProviderUsersQuery } from "gql/queries";
import { providersManagementPaths } from "pages/ProvidersManagement/constants/providers-management-paths";
import {
  providerUserFieldBreadcrumb,
  providerUserListBreadcrumb,
  providerUsersTitleBreadcrumb,
} from "pages/ProviderUsers/constants/provider-users-breadcrumbs";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ProviderCard } from "shared/components";

const ProviderUsersManagement = () => {
  const params = useParams();
  const { t } = useTranslation("admin");
  const { state } = useLocation();
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    if (params?.id) {
      const providerName = (state as any)?.["providerName"];
      setBreadCrumb({
        title: providerUsersTitleBreadcrumb(t),
        values: [
          providerUserListBreadcrumb(t),
          providerUserFieldBreadcrumb(providerName, providersManagementPaths.editPath.fullPathWithParams(params.id)),
        ],
      });
    }
  }, [params?.id]);

  return (
    <GridProvider
      hasTableSetting
      gridName={"providerUsers"}
      columns={useUsersColumns()}
      query={useProviderUsersQuery}
      variables={{
        filter: {
          vendorId: params?.id!,
        },
        sortBy: {
          direction: OrderDirection.Desc,
          field: UserSortField.DateJoined,
        },
      }}
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: false,
      }}
      deleteItemProps={{
        name: "user",
        entityTypeName: "User",
        useDeleteMutation: useProviderUserDeleteMutation,
      }}
      filterInput={{ vendorId: params?.id! }}
      hideFilterInput={true}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions />
          </>
        }
      >
        <>
          <Box m={"20px"}>
            <GridContext.Consumer>
              {({ customTableProps, queryResponse }) => (
                <ProviderCard
                  loading={customTableProps?.isLoading}
                  vendor={(queryResponse as ReturnType<typeof useProviderUsersQuery>["data"])?.users?.edges[0]?.node?.vendor}
                />
              )}
            </GridContext.Consumer>
          </Box>
          <Box m={"20px"}>
            <TableGrid />
          </Box>
        </>
      </PageWrapper>
    </GridProvider>
  );
};

export default ProviderUsersManagement;
