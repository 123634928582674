import { FC, useState } from "react";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { useOptimaActionActivationMutation } from "pages/OptimaActions/gql";

type OptimaActionActivationProps = {
  id: string;
  isActive: boolean;
};

export const OptimaActionActivation: FC<OptimaActionActivationProps> = props => {
  const { id, isActive } = props;

  const [active, setActive] = useState(isActive);

  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();

  const [activateOptimaAction, { loading: isSubmitting }] = useOptimaActionActivationMutation({
    onCompleted: mutationData => {
      setActive(!!mutationData?.updateOptimaActionActiveStatus?.isActive);
      succeeded(t("Optima Action updated successfully"));
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleChangeToggle = () => {
    activateOptimaAction({ variables: { id } });
  };

  return <CustomToggleButton disabled={isSubmitting} isLoading={isSubmitting} checked={active} onChange={handleChangeToggle} />;
};
