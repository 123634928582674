import { i18n } from "@toolkit/i18n";
import { IEnumToOptionsMap } from "../../types";
import { PatientEligibilityBenefitsEnum } from "./type";

export const patientEligibilityBenefitsEnumOptionsMap: IEnumToOptionsMap<PatientEligibilityBenefitsEnum> = {
  [PatientEligibilityBenefitsEnum.Inpatient]: {
    key: PatientEligibilityBenefitsEnum.Inpatient,
    get label() {
      return i18n.t("Inpatient", { ns: "domains" });
    },
    value: PatientEligibilityBenefitsEnum.Inpatient,
  },
  [PatientEligibilityBenefitsEnum.Outpatient]: {
    key: PatientEligibilityBenefitsEnum.Outpatient,
    get label() {
      return i18n.t("Outpatient", { ns: "domains" });
    },
    value: PatientEligibilityBenefitsEnum.Outpatient,
  },
  [PatientEligibilityBenefitsEnum.Maternity]: {
    key: PatientEligibilityBenefitsEnum.Maternity,
    get label() {
      return i18n.t("Maternity", { ns: "domains" });
    },
    value: PatientEligibilityBenefitsEnum.Maternity,
  },
  [PatientEligibilityBenefitsEnum.Dental]: {
    key: PatientEligibilityBenefitsEnum.Dental,
    get label() {
      return i18n.t("Dental", { ns: "domains" });
    },
    value: PatientEligibilityBenefitsEnum.Dental,
  },
  [PatientEligibilityBenefitsEnum.Emergency]: {
    key: PatientEligibilityBenefitsEnum.Emergency,
    get label() {
      return i18n.t("Emergency", { ns: "domains" });
    },
    value: PatientEligibilityBenefitsEnum.Emergency,
  },
  [PatientEligibilityBenefitsEnum.Optical]: {
    key: PatientEligibilityBenefitsEnum.Optical,
    get label() {
      return i18n.t("Optical", { ns: "domains" });
    },
    value: PatientEligibilityBenefitsEnum.Optical,
  },
  [PatientEligibilityBenefitsEnum.Pharmacy]: {
    key: PatientEligibilityBenefitsEnum.Pharmacy,
    get label() {
      return i18n.t("Pharmacy", { ns: "domains" });
    },
    value: PatientEligibilityBenefitsEnum.Pharmacy,
  },
  [PatientEligibilityBenefitsEnum.Laboratory]: {
    key: PatientEligibilityBenefitsEnum.Laboratory,
    get label() {
      return i18n.t("Laboratory", { ns: "domains" });
    },
    value: PatientEligibilityBenefitsEnum.Laboratory,
  },
  [PatientEligibilityBenefitsEnum.Diagnostic]: {
    key: PatientEligibilityBenefitsEnum.Diagnostic,
    get label() {
      return i18n.t("Diagnostic", { ns: "domains" });
    },
    value: PatientEligibilityBenefitsEnum.Diagnostic,
  },
  [PatientEligibilityBenefitsEnum.Radiology]: {
    key: PatientEligibilityBenefitsEnum.Radiology,
    get label() {
      return i18n.t("Radiology", { ns: "domains" });
    },
    value: PatientEligibilityBenefitsEnum.Radiology,
  },
};

export const patientEligibilityBenefitsEnumOptions = Object.values(patientEligibilityBenefitsEnumOptionsMap);
