import { useTranslation } from "@toolkit/i18n";
import { FormTextField, Grid } from "@toolkit/ui";
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from "react";
import { useCustomForm } from "@toolkit/core";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { CancellationReasonsAutocomplete } from "@health/autocompletes";
import { orderCancelFormSchema, orderCancelFormDefaultValues, IOrderCancelFormValues } from "./OrderCancelFormSchema";

export type IOrderCancelFormEvent = {
  type: "SUBMIT";
  payload: {
    id: string;
    values: IOrderCancelFormValues;
  };
};

type OrderCancelFormProps = {
  id: string;
  onChange: (event: IOrderCancelFormEvent) => void;
};

export type OrderCancelFormRef = {
  getForm: () => UseFormReturn<IOrderCancelFormValues>;
  submit: () => void;
};

const OrderCancelFormForwardRef: ForwardRefRenderFunction<OrderCancelFormRef, OrderCancelFormProps> = (props, ref) => {
  const { id, onChange } = props;

  const { t } = useTranslation("domains");

  const form = useCustomForm<IOrderCancelFormValues>({
    defaultValues: orderCancelFormDefaultValues,
    schema: orderCancelFormSchema,
  });

  const { watch, handleSubmit } = form;

  const isSelectedReasonOther = watch("reason")?.value?.text?.toLowerCase() === "other";

  const onSubmit = (values: IOrderCancelFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { id, values },
    });
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CancellationReasonsAutocomplete name={"reason"} />
        </Grid>

        {isSelectedReasonOther && (
          <Grid item xs={12}>
            <FormTextField name={"otherReason"} label={t("Reason")} placeholder={t("Reason")} multiline rows={3} />
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
};

export const OrderCancelForm = forwardRef(OrderCancelFormForwardRef);
