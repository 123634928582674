/* eslint-disable sonarjs/cognitive-complexity */
import _, { get, isDate, isEmpty, set } from "lodash";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { safeGetEncodedSearchParam } from "../GridProvider";
import { CustomTableColumnProps } from "../Table";
import { StaticFilterDialogProps } from "./StaticFilterDialog.types";

export const useStaticFilterDialogHooks = <X extends object>(props: StaticFilterDialogProps<X>) => {
  const { reset } = useFormContext();
  const { filterFields, skipUrlState, setFiltersFormData, isDynamicFilters, onApplyFilters: onSubmit, isOpen, onToggleDialog } = props;
  const filters: typeof props.activeFilters = {};

  props?.activeFilters &&
    Object.keys(props?.activeFilters)?.forEach(k => {
      if (props?.activeFilters?.[k]) {
        set(filters, k, props?.activeFilters[k]);
      }
    });

  const getItemFieldDisplayName = (item: CustomTableColumnProps<X>) => {
    return typeof item?.header === "string" ? item?.header : item?.filter?.name!;
  };
  const [visibleFilters, setVisibleFilters] = useState(
    filterFields?.filter(item => Object.keys(filters).includes(getItemFieldDisplayName(item) as string))
  );
  const handleDynamicFiltersSelectChanged = (_: any, newValue: CustomTableColumnProps<X>[]) => {
    const optionValues = newValue?.map(item => item?.key);
    const selectedField = filterFields?.filter(item => optionValues?.includes(item.key));
    setVisibleFilters(selectedField);
  };
  const filteringItems = props?.isDynamicFilters ? visibleFilters : filterFields;

  const resetValue = (filter: CustomTableColumnProps<X>["filter"]) => {
    if (filter?.type === "autocomplete" && filter?.multiple) {
      return [];
    }
    return null;
  };

  const getValue = <T extends { id: any }>(
    type: NonNullable<CustomTableColumnProps<X>["filter"]>["type"],
    getValueForBackend: (v: T) => any,
    value: T
  ) => {
    const hasGetValueForBackend = typeof getValueForBackend === "function";
    switch (type) {
      case "autocomplete":
        return hasGetValueForBackend ? getValueForBackend(value) : value?.id;
      case "number": {
        return hasGetValueForBackend ? getValueForBackend(value) : value;
      }
      case "string":
      case "date":
        return hasGetValueForBackend ? getValueForBackend(value) : value;
      default:
        return hasGetValueForBackend ? getValueForBackend(value) : value;
    }
  };
  const handleToggle = () => {
    onToggleDialog();
  };

  const handleFormReset = () => {
    const fields = filterFields?.reduce((acc, item) => {
      const fieldNameForBE = item?.filter?.name?.split(".")?.[0]!;
      return {
        ...acc,
        [fieldNameForBE]: resetValue(item?.filter),
      };
    }, {});
    reset(fields);
  };

  const prepareDataToShow = (filter: CustomTableColumnProps<X>["filter"], data: Record<string, any | any[]>) => {
    const value = _.get(data, filter?.name!);
    switch (filter?.type) {
      case "autocomplete":
        return filter?.multiple
          ? Boolean(value?.length) &&
              value?.map((item: any) => (typeof filter?.getOptionLabel === "function" ? filter?.getOptionLabel?.(item) : item?.name))
          : filter?.getOptionLabel?.(value as any) || value?.name;
      case "number": {
        return value;
      }
      default:
        return value;
    }
  };

  const removeEmpty = <T,>(obj: T): T | Partial<T> => {
    return _.isObject(obj)
      ? (_(obj)
          .omitBy(_.isUndefined)
          .omitBy(_.isNull)
          .omitBy(value => {
            return value === "";
          })
          .mapValues(value => {
            if (_.isDate(value)) return value;
            return _.isArray(value) ? value : removeEmpty(value);
          })
          .omitBy(value => !isDate(value) && typeof value === "object" && Object.keys(value!).length === 0)
          .value() as Partial<T>)
      : (obj as T);
  };

  useEffect(() => {
    if (skipUrlState) return;
    const defaultFilter = safeGetEncodedSearchParam("filter");
    if (!isEmpty(defaultFilter)) {
      const filtersForForm = {};
      Object.entries(defaultFilter).forEach(([key, value]) => {
        const field = filterFields?.find(item => item.filter?.name === key);
        if (!field) return;
        // if (field.filter?.type === "autocomplete" && value && !("options" in field.filter)) {
        //   if (field.filter?.multiple) {
        //     filtersForForm[field?.filter.name] = value.map(v => ({
        //       id: v,
        //       value: v,
        //       label: v,
        //     }));
        //   } else {
        //     filtersForForm[field?.filter.name] = {
        //       id: value,
        //       value: value,
        //       label: value,
        //     };
        //   }
        // }
        if (field.filter?.type === "autocomplete" && value) {
          if ("options" in field.filter) {
            if (field.filter?.multiple) {
              filtersForForm[field?.filter.name] = value.map(v => {
                return field.filter?.["options"]?.find(o => (field.filter?.getValueForBackend?.(o) || o.value) === v);
              });
            } else {
              const option = field.filter?.options?.find(option => (field.filter?.getValueForBackend?.(option) || option.value) === value);
              filtersForForm[field?.filter.name] = option;
            }
          }
        } else {
          filtersForForm[field?.filter?.name!] = value;
        }
      });
      console.log("defaultFilter", defaultFilter, "filterFields", filterFields, "filtersForForm", filtersForForm);
      reset(filtersForForm);
      setFiltersFormData(filtersForForm);
    }
  }, []);

  const handleSubmitClicked = (filterFormData: Record<string, any>) => {
    const fields = filteringItems?.reduce((acc, item) => {
      // fieldName: since we use 0 index we can use ! here as if no . it will always return the whole text, so always will be defiend
      const fieldName = item?.filter?.name?.split(".")?.[0]!;
      return {
        ...acc,
        [fieldName]: getValue(item?.filter?.type!, item?.filter?.getValueForBackend!, filterFormData?.[fieldName]),
      };
    }, {} as Record<string, unknown>);

    const filtersValuesForDisplay = filteringItems?.reduce(
      (acc, item) => ({
        ...acc,
        [getItemFieldDisplayName(item)]: prepareDataToShow(item?.filter, filterFormData),
      }),
      {}
    );
    const valuesForBackend = removeEmpty(fields);

    onSubmit(valuesForBackend, filtersValuesForDisplay, filterFormData);
    handleToggle();
  };
  const handleClickClose = () => {
    const { activeFilters } = props;

    const activeFilterKeysWithValue = Object.keys(activeFilters || {})?.filter(fieldName => {
      return ["", undefined].includes(get(activeFilters, fieldName));
    });

    const fields = filterFields?.reduce((acc, item) => {
      const fieldNameForBE = item?.filter?.name?.split(".")?.[0]!;
      const fieldDisplayName = getItemFieldDisplayName(item);
      if (activeFilterKeysWithValue?.includes(fieldDisplayName as string)) {
        acc[fieldNameForBE] = resetValue(item.filter);
      }
      return acc;
    }, {} as Record<string, unknown>);
    reset(fields);
    handleToggle();
  };

  return {
    visibleFilters,
    isDynamicFilters,
    filteringItems,
    isOpen,
    filterFields,
    handleToggle,
    handleFormReset,
    handleClickClose,
    handleSubmitClicked,
    handleDynamicFiltersSelectChanged,
  };
};
