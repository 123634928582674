import React from "react";
import { Box, BoxProps, Typography, TypographyProps, useTheme } from "../../base/mui";

type ValueWithLabelProps = BoxProps & {
  label: string;
  value?: string | React.ReactNode;
  fontSize?: string | number;
  labelProps?: TypographyProps;
  valueProps?: TypographyProps;
  direction?: "row" | "column";
  color?: string;
};

export const ValueWithLabel: React.FC<ValueWithLabelProps> = props => {
  const theme = useTheme();
  const {
    label,
    value,
    fontSize = theme?.mixins.fonts.fontSize.md,
    labelProps,
    valueProps,
    direction = "column",
    color = theme.palette.text.primary,
    ...containerProps
  } = props;
  return (
    <Box
      display='flex'
      flexDirection={direction}
      alignItems={direction === "row" ? "center" : "flex-start"}
      gap={direction === "row" ? 1 : 0.5}
      {...containerProps}
    >
      <Typography fontWeight={theme.mixins.fonts.fontWeight.bold} fontSize={fontSize} color={color} {...labelProps}>
        {label ? label + ":" : ""}
      </Typography>
      <Typography fontSize={fontSize} color={color} {...valueProps}>
        {value}
      </Typography>
    </Box>
  );
};
