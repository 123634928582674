import { DecisionMakerOrderDirection, SubList, SubListSortField } from "@/schema/types";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useSublistDeleteMutation, useSublistListQuery } from "pages/Sublists/gql";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { useSublistListTableColumns } from "./useSublistListTableColumns";

type SublistListContainerProps = {
  onAddNewClick: () => void;
  onEditRowClick: (item: SubList) => void;
};

export const SublistListContainer: FC<SublistListContainerProps> = props => {
  const { onAddNewClick, onEditRowClick } = props;

  return (
    <GridProvider
      hasTableSetting
      gridName='sublistList'
      columns={useSublistListTableColumns()}
      query={useSublistListQuery}
      variables={{
        sortBy: {
          field: SubListSortField.Display,
          direction: DecisionMakerOrderDirection.Asc,
        },
      }}
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
        isDeleteVisible: true,
      }}
      deleteItemProps={{
        useDeleteMutation: useSublistDeleteMutation,
        entityTypeName: "SubList",
        name: "SubList",
      }}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
