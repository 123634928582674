import { OptimaEdit } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { IOptimaEditUpsertFormEvent, OptimaEditUpsertForm } from "pages/OptimaEdits/forms/OptimaEditUpsert/OptimaEditUpsertForm";
import { OptimaEditListDocument, useOptimaEditGetQuery, useOptimaEditUpdateMutation } from "pages/OptimaEdits/gql";
import { convertOptimaEditFormValuesToBackEndValues } from "pages/OptimaEdits/others";
import { IOptimaEditUpdateContainerParams } from "pages/OptimaEdits/types";
import { FC } from "react";
import { useParams } from "react-router-dom";

export type IOptimaEditUpdateContainerEvent = {
  type: "SUBMIT_SUCCESS";
};

type OptimaEditUpdateContainerProps = {
  onChange: (event: IOptimaEditUpdateContainerEvent) => void;
};

export const OptimaEditUpdateContainer: FC<OptimaEditUpdateContainerProps> = props => {
  const { onChange } = props;

  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();

  const { editId } = useParams<IOptimaEditUpdateContainerParams>();

  const { data, loading } = useOptimaEditGetQuery({
    variables: { id: editId! },
    skip: !editId,
    fetchPolicy: "no-cache",
  });

  const optimaEdit = data?.optimaEdit as OptimaEdit;

  const [updateOptimaEdit, { loading: isSubmitting }] = useOptimaEditUpdateMutation({
    onCompleted: () => {
      succeeded(t("Optima Edit updated successfully"));

      onChange({
        type: "SUBMIT_SUCCESS",
      });
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [OptimaEditListDocument],
  });

  const onOptimaEditUpsertFormChange = (event: IOptimaEditUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertOptimaEditFormValuesToBackEndValues(event.payload.values);

      updateOptimaEdit({
        variables: {
          id: editId!,
          input: values,
        },
      });
    }
  };

  return (
    <OptimaEditUpsertForm
      buttonLabel={t("Update")}
      optimaEdit={optimaEdit}
      isLoading={loading}
      isButtonDisabled={isSubmitting}
      onChange={onOptimaEditUpsertFormChange}
    />
  );
};
