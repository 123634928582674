import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaEditListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.OptimaEditFilterInput>;
}>;


export type OptimaEditListQuery = { __typename?: 'Query', optimaEdits?: { __typename?: 'OptimaEditConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null, edges: Array<{ __typename?: 'OptimaEditEdge', node: { __typename?: 'OptimaEdit', id: string, code?: string | null, category?: string | null, subCategory?: string | null, rejectionCode?: string | null, source?: string | null, priority?: Types.OptimaPriority | null, isActive?: boolean | null, actions?: Array<{ __typename?: 'OptimaAction', id: string, action?: string | null } | null> | null } }> } | null };


export const OptimaEditListDocument = gql`
    query OptimaEditList($first: Int, $last: Int, $after: String, $before: String, $filter: OptimaEditFilterInput) {
  optimaEdits(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        code
        category
        subCategory
        rejectionCode
        source
        priority
        isActive
        actions {
          id
          action
        }
      }
    }
  }
}
    `;

/**
 * __useOptimaEditListQuery__
 *
 * To run a query within a React component, call `useOptimaEditListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaEditListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaEditListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOptimaEditListQuery(baseOptions?: Apollo.QueryHookOptions<OptimaEditListQuery, OptimaEditListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaEditListQuery, OptimaEditListQueryVariables>(OptimaEditListDocument, options);
      }
export function useOptimaEditListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaEditListQuery, OptimaEditListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaEditListQuery, OptimaEditListQueryVariables>(OptimaEditListDocument, options);
        }
export function useOptimaEditListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaEditListQuery, OptimaEditListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaEditListQuery, OptimaEditListQueryVariables>(OptimaEditListDocument, options);
        }
export type OptimaEditListQueryHookResult = ReturnType<typeof useOptimaEditListQuery>;
export type OptimaEditListLazyQueryHookResult = ReturnType<typeof useOptimaEditListLazyQuery>;
export type OptimaEditListSuspenseQueryHookResult = ReturnType<typeof useOptimaEditListSuspenseQuery>;
export type OptimaEditListQueryResult = Apollo.QueryResult<OptimaEditListQuery, OptimaEditListQueryVariables>;