import { MedicalForm, QuestionType } from "@/schema/types";
import { medicalFormCategoryOptionsMap, medicalFormTypeOptionsMap, questionTypeOptionsMap } from "@health/enum-options";
import { AlignLeftIcon, CheckboxIcon, MuiExpandCircleDownRoundedIcon, MuiThumbsUpDownIcon, createAutocompleteOption } from "@toolkit/ui";
import { uniqueId } from "lodash";
import { IMedicalFormUpsertFormValues } from "pages/MedicalForms/forms/MedicalFormUpsert/MedicalFormUpsertFormSchema";

export const shouldQuestionHaveOptions = (questionType: QuestionType) => {
  return questionType && questionType !== QuestionType.String;
};

export const getOptionIcon = (questionType: QuestionType, primary: string) => {
  switch (questionType) {
    case QuestionType.Boolean:
      return <MuiThumbsUpDownIcon sx={{ color: primary }} />;
    case QuestionType.MultiChoice:
      return <CheckboxIcon fill={primary} />;
    case QuestionType.SingleChoice:
      return <MuiExpandCircleDownRoundedIcon fill={primary} />;
    case QuestionType.String:
      return <AlignLeftIcon fill={primary} />;
  }
};

export const convertMedicalFormToFormValues = (medicalForm: MedicalForm): IMedicalFormUpsertFormValues => {
  const department = medicalForm.department
    ? createAutocompleteOption({ id: medicalForm?.department?.id, name: medicalForm?.department?.name }, "id", "name")
    : undefined;

  return {
    active: medicalForm.active!,
    name: medicalForm.name!,
    nameAr: medicalForm.nameAr!,
    code: medicalForm.code!,
    category: medicalFormCategoryOptionsMap[medicalForm?.category!],
    type: medicalFormTypeOptionsMap[medicalForm?.type!],
    description: medicalForm.description!,
    welcomeCard: medicalForm.welcomeCard,
    welcomeCardAr: medicalForm.welcomeCardAr,
    department,
    medicalFormClassifications:
      medicalForm.medicalFormClassifications?.map(item => ({
        name: item?.name!,
        rangeFrom: item?.rangeFrom!,
        rangeTo: item?.rangeTo!,
      })) || [],
    questions: medicalForm.questions?.map(item => ({
      question: item.question!,
      questionType: questionTypeOptionsMap[item?.questionType!],
      required: !!item.required,
      image: item?.images?.length ? item?.images?.[0] : undefined,
      options:
        item.options?.map(option => {
          const optionScore = item.questionOptionScores?.find(score => option === score?.option);

          return {
            id: optionScore?.id!,
            value: option!,
            score: optionScore?.score!,
          };
        }) || [],
    })),
  };
};

export const createDefaultQuestionOptions = (questionType: QuestionType) => {
  if (questionType === QuestionType.Boolean) {
    return [
      { id: "yes", value: "Yes", score: null },
      { id: "no", value: "No", score: null },
    ];
  } else {
    return [{ id: uniqueId("field-"), value: "", score: null }];
  }
};
