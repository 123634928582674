import { OptimaEdit } from "@/schema/types";
import { OptimaActionsAutocomplete } from "@health/autocompletes";
import { optimaPriorityOptions } from "@health/enum-options";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormAutocomplete, FormCard, FormSwitch, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { convertOptimaEditToFormValues } from "pages/OptimaEdits/others";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { IOptimaEditUpsertFormValues, optimaEditUpsertFormDefaultValues, optimaEditUpsertFormSchema } from "./OptimaEditUpsertFormSchema";

export type IOptimaEditUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IOptimaEditUpsertFormValues;
  };
};

type OptimaEditUpsertFormProps = {
  optimaEdit?: OptimaEdit;
  buttonLabel: string;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  onChange: (event: IOptimaEditUpsertFormEvent) => void;
};

export const OptimaEditUpsertForm: FC<OptimaEditUpsertFormProps> = props => {
  const { optimaEdit, buttonLabel, isLoading, isButtonDisabled, onChange } = props;

  const { t } = useTranslation("domains");

  const form = useCustomForm<IOptimaEditUpsertFormValues>({
    defaultValues: optimaEditUpsertFormDefaultValues,
    schema: optimaEditUpsertFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IOptimaEditUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (optimaEdit) {
      const _optimaEdit = convertOptimaEditToFormValues(optimaEdit);
      setValues(_optimaEdit);
    }
  }, [optimaEdit]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions hasCancel hasFormButton isLoading={isLoading} formButtonTitle={buttonLabel} newButtonDisabled={isButtonDisabled} />
          }
        >
          <FormCard title={t("Optima Edit Information")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name={"code"} label={t("Code")} placeholder={t("Code")} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name={"category"} label={t("Category")} placeholder={t("Category")} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name={"subCategory"} label={t("Sub Category")} placeholder={t("Sub Category")} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormAutocomplete name={"priority"} label={t("Priority")} options={optimaPriorityOptions} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <OptimaActionsAutocomplete name={"actions"} multiple filter={{ isActive: true }} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name={"source"} label={t("Source")} placeholder={t("Source")} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormTextField name={"details"} label={t("Details")} placeholder={t("Details")} multiline rows={4} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormTextField name={"message"} label={t("Message")} placeholder={t("Message")} multiline rows={4} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name={"rejectionCode"} label={t("Rejection Code")} placeholder={t("Rejection Code")} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormSwitch name={"isActive"} label={t("Active")} />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
