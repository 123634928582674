import { OptimaAction } from "@/schema/types";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useOptimaActionListQuery } from "pages/OptimaActions/gql";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { useOptimaActionListContainerColumns } from "./useOptimaActionListContainerColumns";

export type IOptimaActionListContainerEvent =
  | {
      type: "ADD_CLICK";
    }
  | {
      type: "UPDATE_CLICK";
      payload: {
        item: OptimaAction;
      };
    };

type OptimaActionListContainerProps = {
  onChange: (event: IOptimaActionListContainerEvent) => void;
};

export const OptimaActionListContainer: FC<OptimaActionListContainerProps> = props => {
  const { onChange } = props;

  const onAddNewItemClick = () => {
    onChange({
      type: "ADD_CLICK",
    });
  };

  const onEditRowClick = (item: OptimaAction) => {
    onChange({
      type: "UPDATE_CLICK",
      payload: { item },
    });
  };

  return (
    <GridProvider
      gridName={"optimaActionList"}
      query={useOptimaActionListQuery}
      columns={useOptimaActionListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
