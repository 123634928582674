import { useTranslation } from "@toolkit/i18n";
import { FormLabel, FormNumberField, FormTextField, Grid } from "@toolkit/ui";
import { IMedicalFormClassificationUpsertFormEvent } from "pages/MedicalForms/types";
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import {
  IMedicalFormClassificationUpsertFormValues,
  medicalFormClassificationUpsertFormDefaultValues,
  medicalFormClassificationUpsertFormSchema,
} from "./MedicalFormClassificationUpsertFormSchema";
import { useMedicalFormClassificationUpsertStyle } from "./useMedicalFormClassificationUpsertStyle";

type MedicalFormQuestionsFormProps = {
  classification: IMedicalFormClassificationUpsertFormValues;
  onChange: (event: IMedicalFormClassificationUpsertFormEvent) => void;
};

export type MedicalFormClassificationUpsertFormRef = {
  getForm: () => UseFormReturn<IMedicalFormClassificationUpsertFormValues>;
  submit: () => void;
};

const MedicalFormClassificationUpsertFormForwardRef: ForwardRefRenderFunction<
  MedicalFormClassificationUpsertFormRef,
  MedicalFormQuestionsFormProps
> = (props, ref) => {
  const { classification, onChange } = props;

  const { t } = useTranslation("domains");

  const { classes } = useMedicalFormClassificationUpsertStyle();

  const form = useCustomForm<IMedicalFormClassificationUpsertFormValues>({
    defaultValues: medicalFormClassificationUpsertFormDefaultValues,
    schema: medicalFormClassificationUpsertFormSchema,
  });

  const { setValue, handleSubmit } = form;

  const onSubmit = (values: IMedicalFormClassificationUpsertFormValues) => {
    if (classification) {
      onChange({
        type: "UPDATE",
        payload: { values },
      });
    } else {
      onChange({
        type: "CREATE",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (classification) {
      setValue("name", classification.name);
      setValue("rangeFrom", classification.rangeFrom);
      setValue("rangeTo", classification.rangeTo);
    }
  }, [classification]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormLabel className={classes.titleScore}> {t("Score Values Range")} </FormLabel>
              </Grid>

              <Grid item xs={6}>
                <FormNumberField name={"rangeFrom"} label={t("From")} />
              </Grid>

              <Grid item xs={6}>
                <FormNumberField name={"rangeTo"} label={t("To")} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormTextField name={"name"} label={t("Classification Label")} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const MedicalFormClassificationUpsertForm = forwardRef(MedicalFormClassificationUpsertFormForwardRef);
