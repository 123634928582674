import { Field, SubListItem } from "@/schema/types";
import { formGirdBreakPoints, formGirdSpacing, getMaxLengthValidation, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, DownloadImportActions, FormActions, FormCard, Grid, PageWrapper, TextField } from "@toolkit/ui";
import { SublistItemList } from "pages/Sublists/components";
import { FieldsAutocomplete, SublistItemCreateForm } from "pages/Sublists/forms";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { UploadDialog } from "shared/components";
import { useSublistUpsertForm } from "./useSublistUpsertForm";

type SublistUpsertFormProps = {
  onEditItemRowClick: (item: SubListItem) => void;
};

export const SublistUpsertForm: FC<SublistUpsertFormProps> = props => {
  const { onEditItemRowClick } = props;

  const { t } = useTranslation("domains");

  const { id } = useParams<{ id: string }>();

  const {
    methods,
    errors,
    defaults,
    isSubmitting,
    isDataLoading,
    importData,
    isSubmittingList,
    isUploadDialogOpened,
    handleSubmit,
    handleOnUpload,
    handleOpenImport,
    handleDownloadClicked,
    handleOnCloseImportDialog,
  } = useSublistUpsertForm({ id: id! });

  return (
    <PageWrapper
      actions={
        <Box justifyContent='flex-end' display='flex'>
          <DownloadImportActions
            hasDownload={!!id}
            hasImport={!!id}
            onImportClicked={handleOpenImport}
            onDownloadClicked={handleDownloadClicked}
          />

          <UploadDialog
            isDialogLoading={isSubmittingList}
            isOpen={isUploadDialogOpened}
            onFileUploadedSuccessfully={handleOnUpload}
            handleClose={handleOnCloseImportDialog}
            hasErrors={Boolean(importData?.uploadSubList?.errors?.length)}
            errorMessage={importData?.uploadSubList?.errors?.map(e => `${e?.field} : ${e?.message}`).join(", ")}
            title={t("Upload sublist")}
            subTitle={t("Upload subList you want to get")}
          />

          <FormActions
            hasSave
            hasCancel
            formButtonTitle={t("Save")}
            onSave={handleSubmit}
            loadingIndicators={{ save: isSubmitting }}
            isSaveDisabled={isSubmitting}
          />
        </Box>
      }
    >
      <Grid container direction='column' rowSpacing={2}>
        <Grid item>
          <FormProvider {...methods}>
            <FormCard loading={isDataLoading} title={t("Sublist")} doYouHaveData={!id || Boolean(defaults)}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={formGirdSpacing}>
                  <Grid item {...formGirdBreakPoints}>
                    <TextField
                      label={t("Display name")}
                      fullWidth
                      defaultValue={defaults?.display}
                      {...methods.register("display", {
                        required: getRequiredValidation(t, true),
                        maxLength: getMaxLengthValidation(t, 255),
                        validate: value => value?.trim().length! > 0 || t("Display name is required"),
                      })}
                      error={Boolean(errors?.display?.message)}
                      helperText={errors?.display?.message}
                    />
                  </Grid>

                  <Grid item {...formGirdBreakPoints}>
                    <FieldsAutocomplete defaultValue={defaults?.field! as Field} />
                  </Grid>
                </Grid>
              </form>
            </FormCard>
          </FormProvider>
        </Grid>

        {Boolean(id) && (
          <>
            <Grid item>
              <SublistItemCreateForm id={id!} />
            </Grid>
            <Grid item>
              <SublistItemList id={id!} onEditItemRowClick={onEditItemRowClick} />
            </Grid>
          </>
        )}
      </Grid>
    </PageWrapper>
  );
};
