import { ProcessingStatus } from "@/schema/types";
import { Box, FilterGrid, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC, useCallback } from "react";
import { GridProvider } from "shared/components";
import { useDeleteCodeSystemConceptMutation, useGetCodeSystemConceptsQuery } from "../gql";
import { AdminManagedListDetailsPageContext } from "./AdminManagedListDetailsPageContext";
import { AMLStatusSummary } from "./components/AMLStatusSummary";
import { ActionsMenu } from "./components/ActionsMenu/ActionsMenu";
import StatusAlertsPanel from "./components/StatusAlertsPanel.component";
import { VersionsList } from "./components/VersionsList/VersionsList";
import { useAdminManagedListDetailsPage } from "./useAdminManagedListDetailsPageHook";

/**
 * this page lists concept properties of a code system version,
 * @param {CodeSystemCode} code => CodeSystemCode
 * @param {string} id ==> version id
 * @returns t
 */
const AdminManagedListDetailsPage: FC = () => {
  const pageContextValue = useAdminManagedListDetailsPage();
  const { columnsDefinition: columnsDefinition, id, versionNumber, processingStatus, reviewerUserName, handleEditRow } = pageContextValue;
  const query = useCallback(
    hookBasicOptions => {
      return useGetCodeSystemConceptsQuery({
        ...hookBasicOptions,
        variables: {
          ...hookBasicOptions.variables,
          filter: undefined,
          codeSystemId: id,
          searchParameters: Object.values(hookBasicOptions.variables.filter || {}),
        },
        skip: !id || id == null || id == "new",
      });
    },

    [id]
  );
  return (
    <GridProvider
      hasTableSetting
      gridName={"getCodeSystemConcepts"}
      columns={columnsDefinition}
      query={query}
      skipCall={!id || id == null || id == "new"}
      tableAction={{
        isDeleteVisible: processingStatus === ProcessingStatus.Draft,
        isEditVisible: processingStatus === ProcessingStatus.Draft,
        onEditRow: handleEditRow,
      }}
      deleteItemProps={{
        name: "Item",
        idPropName: "codeSystemConceptId",
        entityTypeName: "CodeSystemConcept",
        useDeleteMutation: useDeleteCodeSystemConceptMutation,
      }}
    >
      <AdminManagedListDetailsPageContext.Provider value={pageContextValue}>
        <PageWrapper
          filters={<FilterGrid ignoreTranslations isDynamicFilters />}
          actions={
            <Box>
              <Box display={"inline-flex"} flexWrap={"nowrap"} alignItems='center'>
                <TableSettingComponent />
                <VersionsList />
                <ActionsMenu />
              </Box>
            </Box>
          }
        >
          <Box mt='-40px' pt={"12px"} pb={"12px"}>
            <AMLStatusSummary processingStatus={processingStatus} />
          </Box>

          {processingStatus && (
            <StatusAlertsPanel processingStatus={processingStatus} versionNumber={versionNumber} userName={reviewerUserName} />
          )}
          <TableGrid />
        </PageWrapper>
      </AdminManagedListDetailsPageContext.Provider>
    </GridProvider>
  );
};

export default AdminManagedListDetailsPage;
