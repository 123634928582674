import { TypographyProps } from "@mui/system";
import { makeStyles } from "../../base/mui";
interface StyleProps {
  maxWidth?: TypographyProps["typography"]["maxWidth"];
  maxLines?: number;
  maxLength?: number;
}

export const useTruncateTypographyStyles = makeStyles<StyleProps>()((theme, { maxWidth = 250, maxLines, maxLength }) => ({
  base: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    cursor: "pointer",
    textOverflow: "ellipsis",
  },
  widthTruncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth,
  },
  rowTruncate: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: maxLines,
    overflow: "hidden",
    whiteSpace: "normal",
    maxWidth: "unset",
  },
  characterTruncate: {
    maxWidth: `calc(${maxLength}ch)`,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));
