import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserProviderListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.UserFilterInput>;
  sortBy?: Types.InputMaybe<Types.UserSortingInput>;
}>;


export type UserProviderListQuery = { __typename?: 'Query', users?: { __typename?: 'UserCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'UserCountableEdge', node: { __typename?: 'User', id: string, fullName?: string | null, email?: string | null, mobile?: string | null, nationalId?: string | null, appRole?: Types.AppRoleTypes | null, vendorUserType?: Types.UserVendorUserType | null, isActive: boolean, dateJoined: any } }> } | null };


export const UserProviderListDocument = gql`
    query UserProviderList($first: Int, $last: Int, $after: String, $before: String, $filter: UserFilterInput, $sortBy: UserSortingInput) {
  users(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        fullName
        email
        mobile
        nationalId
        appRole
        vendorUserType
        isActive
        dateJoined
      }
    }
  }
}
    `;

/**
 * __useUserProviderListQuery__
 *
 * To run a query within a React component, call `useUserProviderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProviderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProviderListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useUserProviderListQuery(baseOptions?: Apollo.QueryHookOptions<UserProviderListQuery, UserProviderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProviderListQuery, UserProviderListQueryVariables>(UserProviderListDocument, options);
      }
export function useUserProviderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProviderListQuery, UserProviderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProviderListQuery, UserProviderListQueryVariables>(UserProviderListDocument, options);
        }
export function useUserProviderListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserProviderListQuery, UserProviderListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserProviderListQuery, UserProviderListQueryVariables>(UserProviderListDocument, options);
        }
export type UserProviderListQueryHookResult = ReturnType<typeof useUserProviderListQuery>;
export type UserProviderListLazyQueryHookResult = ReturnType<typeof useUserProviderListLazyQuery>;
export type UserProviderListSuspenseQueryHookResult = ReturnType<typeof useUserProviderListSuspenseQuery>;
export type UserProviderListQueryResult = Apollo.QueryResult<UserProviderListQuery, UserProviderListQueryVariables>;