import { Address } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { isEmpty } from "lodash-es";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { HealthProgramNetworkProviderActivation } from "../../components/HealthProgramNetworkProviderActivation/HealthProgramNetworkProviderActivation";
import { HealthProgramNetworkProviderAddress } from "../../components/HealthProgramNetworkProviderAddress/HealthProgramNetworkProviderAddress";
import { HealthProgramNetworkProviderVirtualCallEnable } from "../../components/HealthProgramNetworkProviderVirtualCallEnable/HealthProgramNetworkProviderVirtualCallEnable";
import { healthProgramNetworksPaths } from "../../constants";
import { getServicesInformationItems } from "../../others/cellInfo.utils";
import { IHealthProgramNetworkProviderNode } from "../../types";

type HealthProgramNetworkProviderListContainerColumnsProps = {
  networkId: string;
};

export const useHealthProgramNetworkProviderListContainerColumns = (
  props: HealthProgramNetworkProviderListContainerColumnsProps
): CustomTableColumnProps<IHealthProgramNetworkProviderNode>[] => {
  const { networkId } = props;

  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const onShowProfessionalsClick = useCallback(
    (providerId: string) => {
      navigate(healthProgramNetworksPaths.providerProfessionalList.fullPathWithParams({ networkId, providerId }));
    },
    [navigate, networkId]
  );

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ provider }) => provider?.name,
      },
      {
        key: "licenseId",
        header: t("License ID"),
        accessor: ({ provider }) => provider?.taxLicenseNumber,
      },
      {
        key: "phoneNumber",
        header: t("Phone Number"),
        accessor: ({ provider }) => provider?.contactPhoneNumber || provider?.contactMobileNumber,
      },
      {
        key: "address",
        header: t("Address"),
        accessor: ({ provider }) => <HealthProgramNetworkProviderAddress address={provider?.address as Address} />,
      },
      {
        key: "services",
        header: t("Services"),
        type: "info",
        infoCellOptions: {
          title: t("Services"),
          items: ({ services }) => getServicesInformationItems(services),
          emptyMessage: t("There is No Services Information"),
          valueDisplayMode: "list",
          layout: "one-column",
          disableShowButton: ({ services }) => isEmpty(services),
        },
      },
      {
        key: "professional",
        header: t("Professionals"),
        type: "redirect",
        redirectCellOptions: {
          onClick: ({ id }) => onShowProfessionalsClick(id),
          tooltipMessage: row => t("show {{name}} professionals", { name: pickLocalizedValue(row?.provider?.name, row?.provider?.nameAr) }),
        },
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        type: "date",
        accessor: "created",
      },
      {
        key: "virtualCallEnabled",
        header: t("Virtual Call"),
        accessor: ({ id, virtualCallEnabled }) => (
          <HealthProgramNetworkProviderVirtualCallEnable id={id} isEnabled={!!virtualCallEnabled} />
        ),
        filter: getAutocompleteEnumFilter("YesNo", "virtualCallEnabled"),
      },
      {
        key: "isActive",
        header: t("Active"),
        accessor: ({ id, isActive }) => <HealthProgramNetworkProviderActivation id={id} isActive={!!isActive} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, [onShowProfessionalsClick, t]);
};
