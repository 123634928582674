import { AppointmentStatus, Maybe } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomIcon, IconButton, useAddToast, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { IAppointmentCancelFormEvent } from "../../forms/AppointmentCancel/AppointmentCancelForm";
import { useAppointmentCancelMutation } from "../../gql";
import { AppointmentCancelModal, AppointmentCancelModalApi } from "../../modals/AppointmentCancel/AppointmentCancelModal";
import { getDisAllowedAppointmentStatus } from "../../others/utils";

type AppointmentCancelProps = {
  id: string;
  status?: Maybe<AppointmentStatus>;
};

export const AppointmentCancel: FC<AppointmentCancelProps> = props => {
  const { id, status } = props;

  const { t } = useTranslation("admin");
  const theme = useTheme();
  const { succeeded, failed } = useAddToast();

  const onIconButtonClick = () => {
    if (status === AppointmentStatus.Cancelled) return;

    AppointmentCancelModalApi.open({ id });
  };

  const [cancelAppointment, { loading: isSubmitting }] = useAppointmentCancelMutation({
    onCompleted: mutationData => {
      if (mutationData?.appointmentCancelBySupportTeam?.appointment?.id) {
        succeeded(t("Appointment canceled successfully"));
        AppointmentCancelModalApi.close();
      } else {
        failed(t("Appointment cancellation failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update: (cache, { data }) => {
      const normalizedId = cache.identify({ id: data?.appointmentCancelBySupportTeam?.appointment?.id, __typename: "Appointment" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const onAppointmentCancelModalChange = (event: IAppointmentCancelFormEvent) => {
    if (event.type === "SUBMIT") {
      const { reason, otherReason } = event.payload.values;

      const reasonValue = reason?.value;

      cancelAppointment({
        variables: {
          id: event.payload.id,
          input: {
            cancellationReason: reasonValue?.text?.toLowerCase() === "other" ? otherReason! : reasonValue?.text!,
          },
        },
      });
    }
  };
  return (
    <Box>
      <AppointmentCancelModal isLoading={isSubmitting} onChange={onAppointmentCancelModalChange} />

      <IconButton disabled={getDisAllowedAppointmentStatus(status!)} onClick={onIconButtonClick}>
        <CustomIcon icon={"miniClose"} height={30} width={30} viewBox={"0 5 20 10"} color={theme.palette.error.main} />
      </IconButton>
    </Box>
  );
};
