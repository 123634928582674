/* eslint-disable max-statements */
/* eslint-disable sonarjs/cognitive-complexity */
import { z } from "zod";
import { AppointmentType, AppRoleTypes, VendorUserTypes } from "@/schema/types";
import { i18n } from "@toolkit/i18n";
import { isNil } from "lodash";
import moment from "moment/moment";
import { IUserProviderUpsertForm } from "pages/UsersProviders/forms/UserProviderUpsert/UserProviderUpsertFormSchema";
import { doesVendorUserTypeRequireHealthLicense, isAppointmentTypePresent } from "./utils";

export const validateUserVendor = (data: IUserProviderUpsertForm, ctx: z.RefinementCtx) => {
  const vendorUserType = data?.vendorUserType?.value;

  if (data.dateOfBirth) {
    const age = moment().diff(moment(data.dateOfBirth), "years");

    if (age < 18) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["dateOfBirth"],
        message: i18n.t("User must be at least 18 years old"),
      });
    }
  }

  if (doesVendorUserTypeRequireHealthLicense(vendorUserType)) {
    if (isNil(data.healthLicenseNumber)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["healthLicenseNumber"],
        message: i18n.t("Required"),
      });
    }

    if (!data.healthLicenseStartDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["healthLicenseStartDate"],
        message: i18n.t("Required"),
      });
    }

    if (!data.healthLicenseEndDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["healthLicenseEndDate"],
        message: i18n.t("Required"),
      });
    }

    if (
      data.healthLicenseStartDate &&
      data.healthLicenseEndDate &&
      moment(data.healthLicenseEndDate).isSameOrBefore(moment(data.healthLicenseStartDate))
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["healthLicenseEndDate"],
        message: i18n.t("End date must be after start date"),
      });
    }
  }

  if (vendorUserType !== VendorUserTypes.Manager && !data?.appRole) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["appRole"],
      message: i18n.t("Required"),
    });
  }

  if (data.appRole?.value === AppRoleTypes.User) {
    if (data.branches.length < 1) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        path: ["branches"],
        inclusive: true,
        minimum: 1,
        type: "array",
      });
    }

    if (data.branches.length > 1 && !data.defaultBranch) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["defaultBranch"],
        message: i18n.t("Required"),
      });
    }
  } else if (!data.defaultBranch) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["defaultBranch"],
      message: i18n.t("Required"),
    });
  }
};

export const validateUserVendorTypeDoctor = (data: IUserProviderUpsertForm, ctx: z.RefinementCtx) => {
  if (data?.vendorUserType?.value === VendorUserTypes.Doctor) {
    if (!data?.doctorInfo?.seniority) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["doctorInfo", "seniority"],
        message: i18n.t("Required"),
      });
    }

    if (!data?.doctorInfo?.appointmentTypes?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        path: ["doctorInfo", "appointmentTypes"],
        inclusive: true,
        minimum: 1,
        type: "array",
      });
    }

    if (!data?.doctorInfo?.yearsOfExperience) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["doctorInfo", "yearsOfExperience"],
        message: i18n.t("Required"),
      });
    }

    if (isAppointmentTypePresent(data?.doctorInfo?.appointmentTypes, AppointmentType.Online) && isNil(data.doctorInfo?.onlineVisitPrice)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["doctorInfo", "onlineVisitPrice"],
        message: i18n.t("Required"),
      });
    }

    if (isAppointmentTypePresent(data?.doctorInfo?.appointmentTypes, AppointmentType.Onsite) && isNil(data.doctorInfo?.onsiteVisitPrice)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["doctorInfo", "onsiteVisitPrice"],
        message: i18n.t("Required"),
      });
    }

    if (isAppointmentTypePresent(data?.doctorInfo?.appointmentTypes, AppointmentType.AtHome) && isNil(data.doctorInfo?.atHomeVisitPrice)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["doctorInfo", "atHomeVisitPrice"],
        message: i18n.t("Required"),
      });
    }

    if (data.doctorInfo?.qualifications) {
      const { fromDate, toDate } = data.doctorInfo?.qualifications || {};

      if (fromDate && toDate && moment(toDate).isSameOrBefore(moment(fromDate))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["doctorInfo", "qualifications", "toDate"],
          message: i18n.t("To Date must be after than From Date"),
        });
      }
    }
  }
};
