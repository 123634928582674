import { i18n } from "@toolkit/i18n";
import { EligabilityPayersEnum } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const optimaEligabilityPayersTypeOptionsMap: IEnumToOptionsMap<EligabilityPayersEnum> = {
  [EligabilityPayersEnum.Aafiyatpa]: {
    key: EligabilityPayersEnum.Aafiyatpa,
    get label() {
      return i18n.t("Aafiya TPA", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Aafiyatpa,
  },
  [EligabilityPayersEnum.Adnic]: {
    key: EligabilityPayersEnum.Adnic,
    get label() {
      return i18n.t("ADNIC", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Adnic,
  },
  [EligabilityPayersEnum.Almadallah]: {
    key: EligabilityPayersEnum.Almadallah,
    get label() {
      return i18n.t("Almadallah", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Almadallah,
  },
  [EligabilityPayersEnum.Axa]: {
    key: EligabilityPayersEnum.Axa,
    get label() {
      return i18n.t("AXA", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Axa,
  },
  [EligabilityPayersEnum.Daman]: {
    key: EligabilityPayersEnum.Daman,
    get label() {
      return i18n.t("Daman", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Daman,
  },
  [EligabilityPayersEnum.Inaya]: {
    key: EligabilityPayersEnum.Inaya,
    get label() {
      return i18n.t("Inaya", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Inaya,
  },
  [EligabilityPayersEnum.Mednet]: {
    key: EligabilityPayersEnum.Mednet,
    get label() {
      return i18n.t("Mednet", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Mednet,
  },
  [EligabilityPayersEnum.Msh]: {
    key: EligabilityPayersEnum.Msh,
    get label() {
      return i18n.t("MSH", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Msh,
  },
  [EligabilityPayersEnum.Nas]: {
    key: EligabilityPayersEnum.Nas,
    get label() {
      return i18n.t("NAS", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Nas,
  },
  [EligabilityPayersEnum.Nextcare]: {
    key: EligabilityPayersEnum.Nextcare,
    get label() {
      return i18n.t("Nextcare", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Nextcare,
  },
  [EligabilityPayersEnum.Ngi]: {
    key: EligabilityPayersEnum.Ngi,
    get label() {
      return i18n.t("NGI", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Ngi,
  },
  [EligabilityPayersEnum.Saico]: {
    key: EligabilityPayersEnum.Saico,
    get label() {
      return i18n.t("SAICO", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Saico,
  },
  [EligabilityPayersEnum.Sukoon]: {
    key: EligabilityPayersEnum.Sukoon,
    get label() {
      return i18n.t("Sukoon", { ns: "domains" });
    },
    value: EligabilityPayersEnum.Sukoon,
  },
};

export const optimaEligabilityPayersTypeOptions = Object.values(optimaEligabilityPayersTypeOptionsMap);
