import { LazyQueryHookOptions, OperationVariables, useLazyQuery } from "@apollo/client";
import { ReactNode, useCallback, useMemo } from "react";
import { InfoCellOptionsProps, InformationCell } from "../InformationCell";

export type QueryOptionsProps<Row, T, V extends OperationVariables, ExternalData> = {
  useCustomLazyQuery: (baseOptions?: LazyQueryHookOptions<T, V>) => ReturnType<typeof useLazyQuery<T, V>>;
  variables?: (row: Row) => V;
  processor: (data: T) => ExternalData;
  getExternalData?: (data: ExternalData) => void;
};

export type LazyInfoCellProps<Row, T, V extends OperationVariables, ExternalData> = {
  cellInfoOptions?: InfoCellOptionsProps<Row, ExternalData>;
  queryOptions: QueryOptionsProps<Row, T, V, ExternalData>;
};

export const LazyInfoCell = <Row, T, V extends OperationVariables, ExternalData>({
  cellInfoOptions,
  queryOptions,
}: LazyInfoCellProps<Row, T, V, ExternalData>): ReactNode => {
  const { useCustomLazyQuery, variables, processor } = queryOptions;

  const [fetchQuery, { data, loading }] = useCustomLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const processedData = useMemo(() => {
    if (data) {
      return processor(data);
    }
    return null;
  }, [data, processor]);

  const triggerLazyQuery = useCallback(() => {
    if (cellInfoOptions?.row && variables) {
      const queryVariables = variables(cellInfoOptions.row as Row);
      fetchQuery({ variables: queryVariables });
    }
  }, [cellInfoOptions?.row, variables, fetchQuery]);

  return cellInfoOptions?.row ? (
    <InformationCell
      loading={loading}
      onShowButtonClick={triggerLazyQuery}
      {...cellInfoOptions}
      externalData={processedData ?? undefined}
    />
  ) : null;
};
