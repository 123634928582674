import { DecisionPlan, DecisionPlanCategory } from "@/schema/types";
import { FC } from "react";
import { DecisionCreate, IDecisionCreateEvent } from "pages/Decisions/components/DecisionCreate/DecisionCreate";
import { useNavigate } from "react-router-dom";

type DecisionCreateContainerProps = {
  category: DecisionPlanCategory;
  healthParameterCode?: string;
  guidedCareTemplateCode?: string;
  ioAgentCode?: string;
  selectedDecisionPlan?: DecisionPlan;
};

export const DecisionCreateContainer: FC<DecisionCreateContainerProps> = props => {
  const { category, healthParameterCode, guidedCareTemplateCode, ioAgentCode, selectedDecisionPlan } = props;

  const navigate = useNavigate();

  const onDecisionCreateChange = (event: IDecisionCreateEvent) => {
    if (event.type === "CREATE") {
      navigate(-1);
    }
  };

  return (
    <DecisionCreate
      category={category}
      healthParameterCode={healthParameterCode}
      guidedCareTemplateCode={guidedCareTemplateCode}
      ioAgentCode={ioAgentCode}
      selectedDecisionPlan={selectedDecisionPlan}
      onChange={onDecisionCreateChange}
    />
  );
};
