import { i18n } from "@toolkit/i18n";
import { RelationTypes } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const relationTypeOptionsMap: IEnumToOptionsMap<RelationTypes> = {
  [RelationTypes.Brother]: {
    key: RelationTypes.Brother,
    get label() {
      return i18n.t("Brother", { ns: "domains" });
    },
    value: RelationTypes.Brother,
  },
  [RelationTypes.Daughter]: {
    key: RelationTypes.Daughter,
    get label() {
      return i18n.t("Daughter", { ns: "domains" });
    },
    value: RelationTypes.Daughter,
  },
  [RelationTypes.Father]: {
    key: RelationTypes.Father,
    get label() {
      return i18n.t("Father", { ns: "domains" });
    },
    value: RelationTypes.Father,
  },
  [RelationTypes.Grandfather]: {
    key: RelationTypes.Grandfather,
    get label() {
      return i18n.t("Grandfather", { ns: "domains" });
    },
    value: RelationTypes.Grandfather,
  },
  [RelationTypes.Grandmother]: {
    key: RelationTypes.Grandmother,
    get label() {
      return i18n.t("Grandmother", { ns: "domains" });
    },
    value: RelationTypes.Grandmother,
  },
  [RelationTypes.Husband]: {
    key: RelationTypes.Husband,
    get label() {
      return i18n.t("Husband", { ns: "domains" });
    },
    value: RelationTypes.Husband,
  },
  [RelationTypes.Mother]: {
    key: RelationTypes.Mother,
    get label() {
      return i18n.t("Mother", { ns: "domains" });
    },
    value: RelationTypes.Mother,
  },
  [RelationTypes.Other]: {
    key: RelationTypes.Other,
    get label() {
      return i18n.t("Other", { ns: "domains" });
    },
    value: RelationTypes.Other,
  },
  [RelationTypes.Sister]: {
    key: RelationTypes.Sister,
    get label() {
      return i18n.t("Sister", { ns: "domains" });
    },
    value: RelationTypes.Sister,
  },
  [RelationTypes.Son]: {
    key: RelationTypes.Son,
    get label() {
      return i18n.t("Son", { ns: "domains" });
    },
    value: RelationTypes.Son,
  },
  [RelationTypes.Wife]: {
    key: RelationTypes.Wife,
    get label() {
      return i18n.t("Wife", { ns: "domains" });
    },
    value: RelationTypes.Wife,
  },
};

export const relationTypeOptions = Object.values(relationTypeOptionsMap);
