import { HealthProgramMembershipRequest } from "@/schema/types";
import { getMediaLink, useCustomForm } from "@toolkit/core";
import { FormAutocomplete, FormDatePickerField, FormTextField, Grid, Typography } from "@toolkit/ui";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { coverageByTypeOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { FC, useEffect } from "react";
import moment from "moment";
import { convertHealthProgramMembershipRequestToFormValues } from "../../others";
import {
  healthProgramMembershipRequestApproveFormSchema,
  healthProgramMembershipRequestApproveFormDefaultValues,
  IHealthProgramMembershipRequestApproveForm,
} from "./HealthProgramMembershipRequestApproveFormSchema";

export const HealthProgramMembershipRequestApproveFormApi: {
  submit: () => void;
  getForm: () => UseFormReturn<IHealthProgramMembershipRequestApproveForm> | null;
} = {
  submit: () => {},
  getForm: () => null,
};

export type IHealthProgramMembershipRequestApproveFormEvent = {
  type: "SUBMIT";
  payload: {
    healthProgramMembershipRequestId: string;
    values: IHealthProgramMembershipRequestApproveForm;
  };
};

type HealthProgramMembershipRequestApproveFormProps = {
  healthProgramMembershipRequest: HealthProgramMembershipRequest;
  onChange: (event: IHealthProgramMembershipRequestApproveFormEvent) => void;
};

export const HealthProgramMembershipRequestApproveForm: FC<HealthProgramMembershipRequestApproveFormProps> = props => {
  const { healthProgramMembershipRequest, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IHealthProgramMembershipRequestApproveForm>({
    defaultValues: healthProgramMembershipRequestApproveFormDefaultValues,
    schema: healthProgramMembershipRequestApproveFormSchema,
  });
  console.log("form", form.getValues());
  console.log("errors", form.formState.errors);
  const { watch, handleSubmit, setValues } = form;

  const memberShipStartDate = watch("membershipStartDate");

  const onSubmit = (values: IHealthProgramMembershipRequestApproveForm) => {
    onChange({
      type: "SUBMIT",
      payload: {
        healthProgramMembershipRequestId: healthProgramMembershipRequest?.id!,
        values,
      },
    });
  };

  useEffect(() => {
    if (healthProgramMembershipRequest) {
      const values = convertHealthProgramMembershipRequestToFormValues(healthProgramMembershipRequest);
      setValues(values);
    }
  }, [healthProgramMembershipRequest]);

  useEffect(() => {
    HealthProgramMembershipRequestApproveFormApi.submit = form.handleSubmit(onSubmit);
    HealthProgramMembershipRequestApproveFormApi.getForm = () => form;

    return () => {
      HealthProgramMembershipRequestApproveFormApi.submit = () => {};
      HealthProgramMembershipRequestApproveFormApi.getForm = () => null;
    };
  }, [form]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormAutocomplete name={"coverageBy"} label={t("Coverage By")} options={coverageByTypeOptions} />
          </Grid>

          <Grid item xs={6}>
            <FormDatePickerField name={"membershipStartDate"} label={t("Membership Start Date")} placeholder={t("Membership Start Date")} />
          </Grid>

          <Grid item xs={6}>
            <FormDatePickerField
              name={"membershipEndDate"}
              label={t("Membership End Date")}
              placeholder={t("Membership End Date")}
              datePickerProps={{
                minDate: moment(memberShipStartDate).add(1, "d"),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormTextField name={"insuranceClass"} label={t("Insurance Class")} placeholder={t("Insurance Class")} />
          </Grid>

          <Grid item xs={6}>
            <FormTextField name={"insurancePolicyNumber"} label={t("Insurance Policy Number")} placeholder={t("Insurance Policy Number")} />
          </Grid>

          <Grid item xs={6}>
            <FormTextField name={"insuranceCategory"} label={t("Insurance Category")} placeholder={t("Insurance Category")} />
          </Grid>

          <Grid item xs={6}>
            <FormTextField name={"insuranceCategoryCode"} label={t("Insurance Category Code")} placeholder={t("Insurance Category Code")} />
          </Grid>

          {(healthProgramMembershipRequest?.frontInsuranceCardPhoto || healthProgramMembershipRequest?.backInsuranceCardPhoto) && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {healthProgramMembershipRequest?.frontInsuranceCardPhoto && (
                  <Grid item xs={6}>
                    <Typography paragraph> {t("Front Insurance Card Photo")} </Typography>

                    <img
                      height={300}
                      width={300}
                      loading={"lazy"}
                      alt={"front-insurance-card"}
                      src={getMediaLink(healthProgramMembershipRequest.frontInsuranceCardPhoto)}
                    />
                  </Grid>
                )}

                {healthProgramMembershipRequest?.backInsuranceCardPhoto && (
                  <Grid item xs={6}>
                    <Typography paragraph> {t("Back Insurance Card Photo")} </Typography>

                    <img
                      height={300}
                      width={300}
                      loading={"lazy"}
                      alt={"back-insurance-card"}
                      src={getMediaLink(healthProgramMembershipRequest.backInsuranceCardPhoto)}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
};
