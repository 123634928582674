import React, { FC } from "react";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useDecisionGuidedCareTemplatesAutocompleteQuery } from "pages/Decisions/gql";

type DecisionGuidedCareTemplatesAutocompleteProps = {
  name: string;
};

export const DecisionGuidedCareTemplatesAutocomplete: FC<DecisionGuidedCareTemplatesAutocompleteProps> = props => {
  const { name } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useDecisionGuidedCareTemplatesAutocompleteQuery,
    searchKey: "name",
  });

  const options = mapToAutocompleteOptions(data, "id", "display");

  return <FormAutocomplete {...restRelayStyleConnection} name={name} options={options} label={t("Template")} />;
};
