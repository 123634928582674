import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserProviderNationalIdGetQueryVariables = Types.Exact<{
  nationalId: Types.Scalars['String']['input'];
}>;


export type UserProviderNationalIdGetQuery = { __typename?: 'Query', getClinician?: { __typename?: 'Clinician', nationalId?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, tempPassword?: string | null, license?: string | null, licenseStart?: any | null, licenseEnd?: any | null } | null };


export const UserProviderNationalIdGetDocument = gql`
    query UserProviderNationalIdGet($nationalId: String!) {
  getClinician(nationalId: $nationalId) {
    nationalId
    firstName
    lastName
    email
    phone
    tempPassword
    license
    licenseStart
    licenseEnd
  }
}
    `;

/**
 * __useUserProviderNationalIdGetQuery__
 *
 * To run a query within a React component, call `useUserProviderNationalIdGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProviderNationalIdGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProviderNationalIdGetQuery({
 *   variables: {
 *      nationalId: // value for 'nationalId'
 *   },
 * });
 */
export function useUserProviderNationalIdGetQuery(baseOptions: Apollo.QueryHookOptions<UserProviderNationalIdGetQuery, UserProviderNationalIdGetQueryVariables> & ({ variables: UserProviderNationalIdGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProviderNationalIdGetQuery, UserProviderNationalIdGetQueryVariables>(UserProviderNationalIdGetDocument, options);
      }
export function useUserProviderNationalIdGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProviderNationalIdGetQuery, UserProviderNationalIdGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProviderNationalIdGetQuery, UserProviderNationalIdGetQueryVariables>(UserProviderNationalIdGetDocument, options);
        }
export function useUserProviderNationalIdGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserProviderNationalIdGetQuery, UserProviderNationalIdGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserProviderNationalIdGetQuery, UserProviderNationalIdGetQueryVariables>(UserProviderNationalIdGetDocument, options);
        }
export type UserProviderNationalIdGetQueryHookResult = ReturnType<typeof useUserProviderNationalIdGetQuery>;
export type UserProviderNationalIdGetLazyQueryHookResult = ReturnType<typeof useUserProviderNationalIdGetLazyQuery>;
export type UserProviderNationalIdGetSuspenseQueryHookResult = ReturnType<typeof useUserProviderNationalIdGetSuspenseQuery>;
export type UserProviderNationalIdGetQueryResult = Apollo.QueryResult<UserProviderNationalIdGetQuery, UserProviderNationalIdGetQueryVariables>;